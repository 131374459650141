import { mainActionTypes } from '../actions/actionTypes';

const initialState = {
    loading: false,
    signupData: null,
    dropdownData: {},
    rloading: false,
};

export default function user(state = initialState, { type, payload }) {
    switch (type) {
        case mainActionTypes.SIGNUP.REQUEST:
            return {
                ...state,
                loading: true,
                signupData: null,
            };
        case mainActionTypes.SIGNUP.SUCCESS:
            return {
                ...state,
                loading: false,
                signupData: payload,
            };
        case mainActionTypes.SIGNUP.FAILURE:
            return {
                ...state,
                loading: false,
                signupData: null,
            };
        case mainActionTypes.VERIFY.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case mainActionTypes.VERIFY.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case mainActionTypes.VERIFY.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case mainActionTypes.DROPDOWN_GETALL.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case mainActionTypes.DROPDOWN_GETALL.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case mainActionTypes.DROPDOWN_GETALL.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case mainActionTypes.RESEND_CODE.REQUEST:
            return {
                ...state,
                rloading: true,
            };
        case mainActionTypes.RESEND_CODE.SUCCESS:
            return {
                ...state,
                rloading: false,
            };
        case mainActionTypes.RESEND_CODE.FAILURE:
            return {
                ...state,
                rloading: false,
            };
        case mainActionTypes.LOGIN.REQUEST:
            return {
                ...state,
                loading: true
            };
        case mainActionTypes.LOGIN.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case mainActionTypes.LOGIN.FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};
