import HeaderBox from '../warehouseDetail/components/headerBox';
import {useHistory, useLocation} from 'react-router-dom';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';

import {Transition} from '@headlessui/react';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Fragment, useState} from 'react';
import Geocode from 'react-geocode';

import {CheckIcon} from '@heroicons/react/solid';
import WarehouseDetailsForm from './addWarehouseForms/warehouseDetailsForm';
import Storage from './addWarehouseForms/storage';
import WarehouseInfo from './addWarehouseForms/warehouseInfo';
import OptionSection from './addWarehouseForms/optionSection';
import {
	GOOGLE_API_KEY,
	arrayGetValueFilters,
	getState,
	getCity,
	getCountry,
	getStreetAddress,
	getZipcode,
} from '../../../utils';
import PhotoSection from './addWarehouseForms/photoSection';
import {addWarehouseDetail} from '../../../actions';
import {useAlert} from 'react-alert';

Geocode.setApiKey(GOOGLE_API_KEY);

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function AddWarehouse({sendToParent = null}) {
	const [tabIndex, setTabIndex] = useState(0);
	const history = useHistory();
	const alert = useAlert();
	const findlocation = useLocation();
	const [searchLocation, setSearchLocation] = useState('');
	const [location, setLocation] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const [visibleLoc, setVisibleLoc] = useState('');
	const [city, setCity] = useState('');
	const [states, setStates] = useState('');
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');
	const valueFromLocalStorage = localStorage.getItem('filters');
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters('', 'facilityCertifications')
	);
	const [storageInfastructure, setStorageInfastructure] = useState(
		arrayGetValueFilters('', 'storageInfrastructures')
	);
	const [securityComponents, setSecurityComponents] = useState(
		arrayGetValueFilters('', 'securityComponents')
	);
	const [inboundServices, setInboundServices] = useState(
		arrayGetValueFilters('', 'inboundServices')
	);
	const [outboundServices, setOutboundServices] = useState(
		arrayGetValueFilters('', 'outboundServices')
	);
	const [valueAddedServices, setValueAddedServices] = useState(
		arrayGetValueFilters('', 'valueAddedServices')
	);

	const [totalSqaureFootage, setTotalSqaureFootage] = useState('');
	const [totalSqaureFootageFormattted, setTotalSqaureFootageFormattted] =
		useState('');
	const [availableSqaureFootage, setAvailableSqaureFootage] = useState('');
	const [
		availableSqaureFootageFormattted,
		setAvailableSqaureFootageFormattted,
	] = useState('');
	const [availableSoonSqaureFootage, setAvailableSoonSqaureFootage] =
		useState('');
	const [
		availableSoonSqaureFootageFormattted,
		setAvailableSoonSqaureFootageFormattted,
	] = useState('');
	const [warehouseImages, setWarehouseImages] = useState([]);
	//all localStorage filters options list
	const [jsonList, setJsonList] = useState(JSON.parse(valueFromLocalStorage));
	// first step form
	const [stepFirstFrom, setStepFirstFrom] = useState(false);
	const [stepSecondFrom, setStepSecondFrom] = useState(false);
	const [stepThirdFrom, setStepThirdFrom] = useState(false);
	const [stepFourFrom, setStepFourFrom] = useState(false);
	const [finalStep, setFinalStep] = useState(false);

	const [state, setState] = useState({
		warehouseName: '',
		industriesServed: '',
		wmsAvailable: null,
		wmsSupportIntrgration: null,
		alternateWMSOMSOperate: null,
		ecomFulfilmentFimiliar: null,
		prebuildIntegration: '',
	});
	const {pathname} = findlocation;
	const backToMyWareHouses = (e) => {
		e.preventDefault();
		history.push('/my-warehouses');
	};

	const steps = [
		{
			name: 'My Warehouse',
			description: 'Enter the initial details for your warehouse.',
			status:
				tabIndex === 0 ? 'current' : tabIndex > 0 ? 'complete' : 'upcoming',
		},
		{
			name: 'Storage information',
			description: '',
			status:
				tabIndex === 1 ? 'current' : tabIndex > 1 ? 'complete' : 'upcoming',
		},
		{
			name: 'Service Information',
			description: '',
			href: '#',
			status:
				tabIndex === 2 ? 'current' : tabIndex > 2 ? 'complete' : 'upcoming',
		},
		{
			name: 'Technology',
			description: '',
			href: '#',
			status:
				tabIndex === 3 ? 'current' : tabIndex > 3 ? 'complete' : 'upcoming',
		},
		{
			name: 'Photos',
			description: '',
			href: '#',
			status:
				tabIndex === 4 ? 'current' : tabIndex > 4 ? 'complete' : 'upcoming',
		},
	];

	const handleAddressSelect = (place) => {
		if (place && place.formatted_address) {
			const address = place.formatted_address,
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			Geocode.fromLatLng(latValue, lngValue).then(
				(response) => {
					const addressArray = response.results[0].address_components;
					setLatitude(latValue);
					setLongitude(lngValue);
					setCity(getCity(addressArray));
					setStates(getState(addressArray));
					setCountry(getCountry(addressArray));
					// const streetAddress = getStreetAddress(addressArray);
					setZip(getZipcode(addressArray));
					setVisibleLoc(address);
					let vLoc = address.split(',');
					vLoc =
						vLoc.length === 1
							? vLoc[0]
							: vLoc.length > 2
							? `${vLoc[0]},${vLoc[1]}`
							: address;
					setLocation(vLoc);
					setSearchLocation('');
				},
				(error) => {
					console.error(error);
				}
			);
		} else {
			setCity('');
			setStates('');
			setCountry('');
			setZip('');
			setLocation(null);
			setSearchLocation('');
			setVisibleLoc('');
			setLatitude(null);
			setLongitude(null);
		}
	};

	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};

	const handleDropdownChangeStorage = (e) => {
		setStorageInfastructure(e);
	};

	const handleDropdownChangeSecurity = (e) => {
		setSecurityComponents(e);
	};

	const handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		if (name === 'totalSKU') {
			if (value === '') {
				setState({
					...state,
					[e.target.name]: value,
				});
			} else {
				if (!Number(value)) {
					return;
				} else {
					setState({
						...state,
						[e.target.name]: value,
					});
				}
			}
		} else {
			setState({
				...state,
				[e.target.name]: value,
			});
		}
	};
	const validateOverView = (e) => {
		e.preventDefault();
		if (
			state.warehouseName &&
			location &&
			facilityList.length > 0 &&
			latitude !== '' &&
			latitude !== 0 &&
			longitude !== '' &&
			longitude !== 0 &&
			longitude !== null &&
			latitude !== null
		) {
			setStepFirstFrom(false);
			setTabIndex(1);
		} else {
			setStepFirstFrom(true);
		}
	};
	const validateBack = (e, indexType) => {
		e.preventDefault();
		setTabIndex(indexType);
	};
	const validateStorage = (e) => {
		e.preventDefault();
		if (
			totalSqaureFootage &&
			availableSqaureFootage &&
			parseFloat(availableSqaureFootage) <= parseFloat(totalSqaureFootage) &&
			parseFloat(totalSqaureFootage) > 0 &&
			parseFloat(availableSqaureFootage) > 0 &&
			storageInfastructure.length > 0 &&
			securityComponents.length > 0 &&
			state.industriesServed
		) {
			setStepSecondFrom(false);
			setTabIndex(2);
		} else {
			setStepSecondFrom(true);
		}
	};
	const handleCheckBoxChange = (e) => {
		let value = [...inboundServices];
		if (e.target.checked) {
			value.push(parseInt(e.target.value));
			setInboundServices([...value]);
		} else {
			value = value.filter((item) => item !== parseInt(e.target.value));
			setInboundServices([...value]);
		}
	};
	const handleCheckBoxChangeValue = (e) => {
		let value = [...valueAddedServices];
		if (e.target.checked) {
			value.push(parseInt(e.target.value));
			setValueAddedServices([...value]);
		} else {
			value = value.filter((item) => item !== parseInt(e.target.value));
			setValueAddedServices([...value]);
		}
	};
	const handleCheckBoxChangeout = (e) => {
		let value = [...outboundServices];
		if (e.target.checked) {
			value.push(parseInt(e.target.value));
			setOutboundServices([...value]);
		} else {
			value = value.filter((item) => item !== parseInt(e.target.value));
			setOutboundServices([...value]);
		}
	};
	const handleRadioButtonChange = (e) => {
		const value = e.target.value;
		setState({
			...state,
			[e.target.name]: value === 'true' ? true : false,
		});
	};
	const validateInfo = (e) => {
		e.preventDefault();
		if (inboundServices.length > 0 && outboundServices.length > 0) {
			setTabIndex(3);
			setStepThirdFrom(false);
		} else {
			setStepThirdFrom(true);
		}
	};
	const validateOption = (e) => {
		e.preventDefault();
		if (
			state.wmsAvailable !== null &&
			state.wmsSupportIntrgration !== null &&
			state.alternateWMSOMSOperate !== null &&
			state.ecomFulfilmentFimiliar !== null
		) {
			setStepFourFrom(false);
			setTabIndex(4);
		} else {
			setStepFourFrom(true);
		}
	};
	const handleImageFile = (e) => {
		e.preventDefault();
		let index = 0;
		let allFiles = [];
		const imagesData = warehouseImages ? warehouseImages : [];
		let lastIndex = 0;
		if (imagesData && imagesData.length > 0) {
			lastIndex = imagesData[imagesData.length - 1].tempId;
		}
		for (let file of e.target.files) {
			if (file.type.includes('image/')) {
				allFiles.push(file);
			}
		}
		var uploadFile = () => {
			if (index < allFiles.length) {
				let file = allFiles[index];
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					imagesData.push({
						id: null,
						imageURL: reader.result,
						isDeleted: false,
						tempId: lastIndex + 1,
						image: file,
					});
					lastIndex = lastIndex + 1;
					index++;
					uploadFile();
				};
				reader.onerror = (error) => {
					index++;
					uploadFile();
				};
			} else {
				setWarehouseImages([...imagesData]);
			}
		};
		uploadFile();
	};
	const onFileDelete = (index) => {
		const images = [...warehouseImages];
		if (images[index]) {
			images[index].isDeleted = true;
		}
		setWarehouseImages([...images]);
	};

	const completeWarehouse = (e) => {
		e.preventDefault();
		setFinalStep(true);
		let formData = new FormData();
		formData.append('WarehouseName', state.warehouseName);
		formData.append('LocationAddress', location);
		formData.append('City', city);
		formData.append('State', states);
		formData.append('Country', country);
		formData.append('Zipcode', zip);
		formData.append('Latitude', latitude === null ? 0 : latitude);
		formData.append('Longitude', longitude === null ? 0 : longitude);
		formData.append('TotalSqaureFootage', parseFloat(totalSqaureFootage));
		formData.append(
			'AvailableSoonSqaureFootage',
			parseFloat(availableSoonSqaureFootage)
		);
		formData.append(
			'AvailableSqaureFootage',
			parseFloat(availableSqaureFootage)
		);
		formData.append(
			'StorageInfastructure',
			storageInfastructure
				.map((ele) => {
					return ele.value;
				})
				.join(',')
		);
		formData.append(
			'FacilityCertifications',
			facilityList
				.map((ele) => {
					return ele.value;
				})
				.join(',')
		);
		formData.append(
			'SecurityComponents',
			securityComponents
				.map((ele) => {
					return ele.value;
				})
				.join(',')
		);
		formData.append('IndustriesServed', state.industriesServed);
		formData.append('InboundServices', inboundServices.join(','));
		formData.append('OutboundServices', outboundServices.join(','));
		formData.append('ValueAddedServices', valueAddedServices.join(','));
		formData.append('WMSAvailable', state.wmsAvailable);
		formData.append('WMSSupportIntrgration', state.wmsSupportIntrgration);
		formData.append('AlternateWMSOMSOperate', state.alternateWMSOMSOperate);
		formData.append('EcomFulfilmentFimiliar', state.ecomFulfilmentFimiliar);
		formData.append('PhotoSpheres', []);
		if (warehouseImages && warehouseImages.length > 0) {
			let i = 0;
			warehouseImages.forEach((ele, index) => {
				if (ele.id) {
					formData.append('Images[' + i + '].Id', ele.id);
					formData.append(
						'Images[' + i + '].Image',
						ele.image ? ele.image : ele.imageURL
					);
					formData.append('Images[' + i + '].IsDeleted', ele.isDeleted);
					i++;
				} else if (!ele.id && !ele.isDeleted) {
					formData.append(
						'Images[' + i + '].Image',
						ele.image ? ele.image : ele.imageURL
					);
					formData.append('Images[' + i + '].IsDeleted', ele.isDeleted);
					i++;
				}
			});
		}

		addWarehouseDetail(formData, history)
			.then((resp) => {
				setFinalStep(false);
				alert.show(
					{title: 'Sucsess', discription: 'Warehouse created successfully'},
					{type: 'success'}
				);
				if (pathname === '/add-warehouse') {
					history.push('/my-warehouses');
				} else {
					sendToParent(2);
				}
			})
			.catch((err) => {
				//
				setFinalStep(false);
				alert.show(
					{title: 'Error', discription: 'Something went wrong'},
					{type: 'error'}
				);
			});
	};

	return (
		<Fragment>
			{pathname === '/add-warehouse' && (
				<header className="shadow-sm bg-gray-200">
					<HeaderBox backToMyWareHouses={backToMyWareHouses} />
				</header>
			)}
			<TableWrapperHoc>
				<Tabs
					selectedIndex={tabIndex}
					onSelect={(index) => setTabIndex(index)}
					className="h-full w-full"
				>
					<div className="flex flex-1 md:flex-row flex-col h-full w-full rounded-lg overflow-hidden">
						<div className="flex md:max-w-sm w-full h-20 md:h-full">
							<nav aria-label="Progress">
								<TabList className="overflow-hidden">
									{steps.map((step, stepIdx) => (
										<Tab
											key={step.name}
											className={classNames(
												stepIdx !== steps.length - 1 ? 'pb-10' : '',
												'relative'
											)}
										>
											{step.status === 'complete' ? (
												<>
													{stepIdx !== steps.length - 1 ? (
														<div
															className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
															aria-hidden="true"
														/>
													) : null}
													<a
														href={step.href}
														className="relative flex items-start group"
													>
														<span className="h-9 flex items-center">
															<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
																<CheckIcon
																	className="w-5 h-5 text-white"
																	aria-hidden="true"
																/>
															</span>
														</span>
														<span className="ml-4 min-w-0 flex flex-col">
															<span className="text-xs font-semibold tracking-wide uppercase">
																{step.name}
															</span>
															<span className="text-sm text-gray-500">
																{step.description}
															</span>
														</span>
													</a>
												</>
											) : step.status === 'current' ? (
												<>
													{stepIdx !== steps.length - 1 ? (
														<div
															className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
															aria-hidden="true"
														/>
													) : null}
													<a
														href={step.href}
														className="relative flex items-start group"
														aria-current="step"
													>
														<span
															className="h-9 flex items-center"
															aria-hidden="true"
														>
															<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
																<span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
															</span>
														</span>
														<span className="ml-4 min-w-0 flex flex-col">
															<span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
																{step.name}
															</span>
															<span className="text-sm text-gray-500">
																{step.description}
															</span>
														</span>
													</a>
												</>
											) : (
												<>
													{stepIdx !== steps.length - 1 ? (
														<div
															className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
															aria-hidden="true"
														/>
													) : null}
													<a
														href={step.href}
														className="relative flex items-start group"
													>
														<span
															className="h-9 flex items-center"
															aria-hidden="true"
														>
															<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
																<span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
															</span>
														</span>
														<span className="ml-4 min-w-0 flex flex-col">
															<span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
																{step.name}
															</span>
															<span className="text-sm text-gray-500">
																{step.description}
															</span>
														</span>
													</a>
												</>
											)}
										</Tab>
									))}
								</TabList>
							</nav>
						</div>
						{/* add padding to the right block here if needed */}
						<div className="w-full h-full">
							<TabPanel>
								<Transition
									appear
									show={true}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<WarehouseDetailsForm
										searchLocation={searchLocation}
										handleAddressSelect={handleAddressSelect}
										setLocation={setLocation}
										setSearchLocation={setSearchLocation}
										GOOGLE_API_KEY={GOOGLE_API_KEY}
										location={location}
										jsonList={jsonList}
										facilityList={facilityList}
										handleDropdownChangeFacility={handleDropdownChangeFacility}
										handleChange={handleChange}
										state={state}
										city={city}
										zip={zip}
										country={country}
										states={states}
										validateOverView={validateOverView}
										stepFirstFrom={stepFirstFrom}
										setCity={setCity}
										setZip={setZip}
										setCountry={setCountry}
										setStates={setStates}
										longitude={longitude}
									/>
								</Transition>
							</TabPanel>
							<TabPanel>
								<Transition
									appear
									show={true}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Storage
										state={state}
										jsonList={jsonList}
										stepSecondFrom={stepSecondFrom}
										handleChange={handleChange}
										storageInfastructure={storageInfastructure}
										securityComponents={securityComponents}
										handleDropdownChangeStorage={handleDropdownChangeStorage}
										handleDropdownChangeSecurity={handleDropdownChangeSecurity}
										totalSqaureFootage={totalSqaureFootage}
										setTotalSqaureFootage={setTotalSqaureFootage}
										totalSqaureFootageFormattted={totalSqaureFootageFormattted}
										setTotalSqaureFootageFormattted={
											setTotalSqaureFootageFormattted
										}
										availableSqaureFootage={availableSqaureFootage}
										setAvailableSqaureFootage={setAvailableSqaureFootage}
										availableSqaureFootageFormattted={
											availableSqaureFootageFormattted
										}
										setAvailableSqaureFootageFormattted={
											setAvailableSqaureFootageFormattted
										}
										validateBack={validateBack}
										validateStorage={validateStorage}
										availableSoonSqaureFootageFormattted={
											availableSoonSqaureFootageFormattted
										}
										setAvailableSoonSqaureFootageFormattted={
											setAvailableSoonSqaureFootageFormattted
										}
										setAvailableSoonSqaureFootage={
											setAvailableSoonSqaureFootage
										}
									/>
								</Transition>
							</TabPanel>
							<TabPanel>
								<Transition
									appear
									show={true}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<WarehouseInfo
										jsonList={jsonList}
										inboundServices={inboundServices}
										handleCheckBoxChange={handleCheckBoxChange}
										outboundServices={outboundServices}
										valueAddedServices={valueAddedServices}
										handleCheckBoxChangeout={handleCheckBoxChangeout}
										handleCheckBoxChangeValue={handleCheckBoxChangeValue}
										validateInfo={validateInfo}
										validateBack={validateBack}
										stepThirdFrom={stepThirdFrom}
									/>
								</Transition>
							</TabPanel>
							<TabPanel>
								<Transition
									appear
									show={true}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<OptionSection
										state={state}
										validateBack={validateBack}
										handleRadioButtonChange={handleRadioButtonChange}
										validateOption={validateOption}
										stepFourFrom={stepFourFrom}
										handleChange={handleChange}
									/>
								</Transition>
							</TabPanel>
							<TabPanel>
								<Transition
									appear
									show={true}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<PhotoSection
										validateBack={validateBack}
										handleImageFile={handleImageFile}
										warehouseImages={warehouseImages}
										onFileDelete={onFileDelete}
										completeWarehouse={completeWarehouse}
										finalStep={finalStep}
									/>
								</Transition>
							</TabPanel>
						</div>
					</div>
				</Tabs>
			</TableWrapperHoc>
		</Fragment>
	);
}
