import { mainActionTypes } from '../actions/actionTypes';

const initialState = {
    loading: false,
    exploreShippers: [],
    singleShipperDetail: null,
    contracts: [],
    proposals: [],
    incomingProposals: [],
    warehouses: []
};

export default function wareHouseReducer(state = initialState, { type, payload }) {
    switch (type) {
        case mainActionTypes.EXPLORE_SHIPPERS.REQUEST:
            return {
                ...state,
                loading: true,
                exploreShippers: []
            };
        case mainActionTypes.EXPLORE_SHIPPERS.SUCCESS:
            return {
                ...state,
                loading: false,
                exploreShippers: payload,
            };
        case mainActionTypes.EXPLORE_SHIPPERS.FAILURE:
            return {
                ...state,
                loading: false,
                exploreShippers: []
            };
        case mainActionTypes.GET_SOW_ID.REQUEST:
            return {
                ...state,
                loading: true,
                singleShipperDetail: null
            };
        case mainActionTypes.GET_SOW_ID.SUCCESS:
            return {
                ...state,
                loading: false,
                singleShipperDetail: payload,
            };
        case mainActionTypes.GET_SOW_ID.FAILURE:
            return {
                ...state,
                loading: false,
                singleShipperDetail: null
            };
        case mainActionTypes.GET_CONTRACTS.REQUEST:
            return {
                ...state,
                loading: true,
                contracts: []
            };
        case mainActionTypes.GET_CONTRACTS.SUCCESS:
            return {
                ...state,
                loading: false,
                contracts: payload,
            };
        case mainActionTypes.GET_CONTRACTS.FAILURE:
            return {
                ...state,
                loading: false,
                contracts: []
            };
        case mainActionTypes.GET_PROPOSALS.REQUEST:
            return {
                ...state,
                loading: true,
                proposals: []
            };
        case mainActionTypes.GET_PROPOSALS.SUCCESS:
            return {
                ...state,
                loading: false,
                proposals: payload,
            };
        case mainActionTypes.GET_PROPOSALS.FAILURE:
            return {
                ...state,
                loading: false,
                proposals: []
            };
        case mainActionTypes.GET_INCOMING_PROPOSALS.REQUEST:
            return {
                ...state,
                loading: true,
                incomingProposals: []
            };
        case mainActionTypes.GET_INCOMING_PROPOSALS.SUCCESS:
            return {
                ...state,
                loading: false,
                incomingProposals: payload,
            };
        case mainActionTypes.GET_INCOMING_PROPOSALS.FAILURE:
            return {
                ...state,
                loading: false,
                incomingProposals: []
            };
        case mainActionTypes.GET_WAREHOUSE_BY_USER.REQUEST:
            return {
                ...state,
                loading: true,
                warehouses: []
            };
        case mainActionTypes.GET_WAREHOUSE_BY_USER.SUCCESS:
            return {
                ...state,
                loading: false,
                warehouses: payload,
            };
        case mainActionTypes.GET_WAREHOUSE_BY_USER.FAILURE:
            return {
                ...state,
                loading: false,
                warehouses: []
            };
        default:
            return state;
    }
};
