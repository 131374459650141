import { mainActionTypes } from '../actions/actionTypes';

const initialState = {
    loading: false,
    subscription: null,
    haspaymentVal: null,
    haspaymentStatus: null,
    planList: [],
};

export default function paymentReducer(state = initialState, { type, payload }) {
    switch (type) {
        case mainActionTypes.GET_MY_SUBSCRIPTION.REQUEST:
            return {
                ...state,
                loading: true,
                subscription: null
            };
        case mainActionTypes.GET_MY_SUBSCRIPTION.SUCCESS:
            return {
                ...state,
                loading: false,
                subscription: 200,
            };
        case mainActionTypes.GET_MY_SUBSCRIPTION.FAILURE:
            return {
                ...state,
                loading: false,
                subscription: 400
            };
        case mainActionTypes.HAS_PAYMENT_METHOD.REQUEST:
            return {
                ...state,
                loading: true,
                haspaymentVal: null,
                haspaymentStatus: null
            };
        case mainActionTypes.HAS_PAYMENT_METHOD.SUCCESS:
            return {
                ...state,
                loading: false,
                haspaymentVal: payload,
                haspaymentStatus: 200
            };
        case mainActionTypes.HAS_PAYMENT_METHOD.FAILURE:
            return {
                ...state,
                loading: false,
                haspaymentVal: null,
                haspaymentStatus: 400
            };
        case mainActionTypes.ADD_USER_PAYMENT_METHOD.REQUEST:
            return {
                ...state,
                loading: true,
                haspaymentVal: null,
                haspaymentStatus: null
            };
        case mainActionTypes.ADD_USER_PAYMENT_METHOD.SUCCESS:
            return {
                ...state,
                loading: false,
                haspaymentVal: payload,
                haspaymentStatus: 200
            };
        case mainActionTypes.ADD_USER_PAYMENT_METHOD.FAILURE:
            return {
                ...state,
                loading: false,
                haspaymentVal: null,
                haspaymentStatus: 400
            };
        case mainActionTypes.GET_SUBSCRIPTION_LIST.REQUEST:
            return {
                ...state,
                loading: true,
                planList: []
            };
        case mainActionTypes.GET_SUBSCRIPTION_LIST.SUCCESS:
            return {
                ...state,
                loading: false,
                planList: payload,
            };
        case mainActionTypes.GET_SUBSCRIPTION_LIST.FAILURE:
            return {
                ...state,
                loading: false,
                planList: []
            };
        case mainActionTypes.SUBSCRIBED_PLAN.REQUEST:
            return {
                ...state,
                loading: true,
                subscription: null
            };
        case mainActionTypes.SUBSCRIBED_PLAN.SUCCESS:
            return {
                ...state,
                loading: false,
                subscription: 200,
            };
        case mainActionTypes.SUBSCRIBED_PLAN.FAILURE:
            return {
                ...state,
                loading: false,
                subscription: 400
            };
        default:
            return state;
    }
};
