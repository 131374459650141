/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {isArrayLengthExist, addCommas} from '../../../../utils';
import {
	getProposalByIdService,
	submitProposalMethod,
} from '../../../../actions';
import Modal1 from '../../CommonModules/modals';
import Modal from './modal';
import ProposalModal from '../../CommonModules/modals/proposalModal';
import * as SOWEnum from '../../../../utils/sow.enum';
import Lottie from 'react-lottie';
import threeDots from '../../../../assets/anim/threedots_pulse.json';
import ConfirmScopeModal from '../../CommonModules/modals/confirmScopeModal';

export default function MyProposals({list}) {
	const {proposals} = useSelector((state) => state.warehouseData);
	const [isOpen, setIsOpen] = useState(false);
	const [proposalData, setProposalData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [loc, setLoc] = useState(null);
	const [comId, setComId] = useState(null);
	const [changeChildView, setChangeChildView] = useState(false);
	const handleScope = (id, name, location, propId) => {
		setComId(propId);
		setProposalData(null);
		getProposalByIdService(id)
			.then((resp) => {
				setIsOpen(true);
				setProposalData(resp);
				setIsLoading(true);
				setLoc(`${name}-${location}`);
			})
			.catch((err) => {
				setIsOpen(false);
				setProposalData(null);
				setIsLoading(false);
				setLoc(null);
				setComId(null);
			});
	};
	const closeModal = (val) => {
		setIsOpen(val);
		setIsLoading(false);
		setLoc(null);
		setComId(null);
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: threeDots,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const submitProposal = (val) => {
		const obj = {
			proposalId: comId,
			proposalStatus: Number(val),
		};
		submitProposalMethod(obj)
			.then((resp) => {
				closeModal(false);
			})
			.catch((err) => {
				closeModal(false);
			});
	};
	const triggerMethod = (val) => {
		submitProposal(val);
	};

	return (
		<Fragment>
			{isLoading && isOpen && (
				<Modal
					isOpen={isOpen}
					setOpen={closeModal}
					modalTitle={'Proposal Detail'}
					modalSubTitle={loc}
				>
					<ProposalModal val={proposalData} triggerMethod={triggerMethod} />
				</Modal>
			)}
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							{' '}
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Proposal Status
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Scope Name
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Location
										</th>

										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											My Proposal
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											<span className="sr-only">Status</span>
											Status
										</th>
									</tr>
								</thead>
								<tbody>
									{isArrayLengthExist(proposals)
										? proposals.map((val, index) => (
												<tr
													key={index}
													className={
														index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
													}
												>
													<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
														{val.outgoingIncoming || ''}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{val.scopeName || '-'}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{val.location || ''}
													</td>

													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{!isOpen && comId === val.proposalId ? (
															<Lottie
																isClickToPauseDisabled={true}
																height={24}
																width={80}
																options={defaultOptions}
															/>
														) : (
															<a
																onClick={() =>
																	handleScope(
																		val.proposalId,
																		val.warehouseName,
																		val.location,
																		val.proposalId
																	)
																}
																className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
															>
																{'View Proposal'}
															</a>
														)}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														<a className="text-indigo-600 hover:text-indigo-900">
															{val.proposalStatus || '-'}
														</a>
													</td>
												</tr>
										  ))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
