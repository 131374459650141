import { useState } from "react";
import * as SOWEnum from "../../../../utils/sow.enum";
import NumberFormat from "react-number-format";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import SpinnerLottie from "../../CommonModules/spinnerLottie";
import { submitProposalWarehouse } from "../../../../actions";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

const SubmitProposal = (props) => {
  const { list, scopeId, setShowProposal, closeModal } = props;
  const history = useHistory();
  const [state, setState] = useState({
    storageUoM: "",
    storageUoMOther: "",
    handlingInUoM: "",
    handlingInUoMOther: "",
    handlingOutUoM: "",
    handlingOutUoMOther: "",
    caseOutUoM: "",
    caseOutUoMOther: "",
    orderProcessingUoM: "",
    orderProcessingUoMOther: "",
    gradeAPalletUoM: "",
    gradeAPalletUoMOther: "",
    shrinkWrapUoM: "",
    shrinkWrapUoMOther: "",
    warehouseId: "",

    orderProcessingRateFormatted: "",
  });
  const [sowId, setSowId] = useState(scopeId);
  const [submitted, setSubmitted] = useState(false);
  const [storageRate, setStorageRate] = useState("");
  const [handlingInRate, setHandlingInRate] = useState("");
  const [handlingOutRate, setHandlingOutRate] = useState("");
  const [caseOutRate, setCaseOutRate] = useState("");
  const [gradeAPalletRate, setGradeAPalletRate] = useState("");
  const [shrinkWrapRate, setShrinkWrapRate] = useState("");
  const [orderProcessingRate, setOrderProcessingRate] = useState("");
  const [storageRateFormatted, setStorageRateFormatted] = useState("");
  const [handlingInRateFormatted, setHandlingInRateFormatted] = useState("");
  const [handlingOutRateFormatted, setHandlingOutRateFormatted] = useState("");
  const [caseOutRateFormatted, setCaseOutRateFormatted] = useState("");
  const [orderProcessingRateFormatted, setOrderProcessingRateFormatted] = useState("");
  const [gradeAPalletRateFormatted, setGradeAPalletRateFormatted] = useState("");
  const [shrinkWrapRateFormatted, setShrinkWrapRateFormatted] = useState("");
  const alert = useAlert();

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const uomList = [
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Pallet),
      value: SOWEnum.UoM.Pallet,
    },
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Crate),
      value: SOWEnum.UoM.Crate,
    },
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Box),
      value: SOWEnum.UoM.Box,
    },
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Supersack),
      value: SOWEnum.UoM.Supersack,
    },
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Gaylord),
      value: SOWEnum.UoM.Gaylord,
    },
    {
      label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Other),
      value: SOWEnum.UoM.Other,
    },
  ];
  const submitApiCall = (e) => {
    if (sowId !== "" && state.warehouseId !== "") {
      setSubmitted(true);
      let data = {
        SOWId: sowId,
        StorageRate: storageRate ? parseFloat(storageRate) : null,
        StorageUoM: state.storageUoM ? parseInt(state.storageUoM) : null,
        StorageUoMOther: state.storageUoM ? (parseInt(state.storageUoM) === SOWEnum.UoM.Other ? state.storageUoMOther : null) : null,
        HandlingInRate: handlingInRate ? parseFloat(handlingInRate) : null,
        HandlingInUoM: state.handlingInUoM ? parseInt(state.handlingInUoM) : null,
        HandlingInUoMOther: state.handlingInUoM
          ? parseInt(state.handlingInUoM) === SOWEnum.UoM.Other
            ? state.handlingInUoMOther
            : null
          : null,
        HandlingOutRate: handlingOutRate ? parseFloat(handlingOutRate) : null,
        HandlingOutUoM: state.handlingOutUoM ? parseInt(state.handlingOutUoM) : null,
        HandlingOutUoMOther: state.handlingOutUoM
          ? parseInt(state.handlingOutUoM) === SOWEnum.UoM.Other
            ? state.handlingOutUoMOther
            : null
          : null,
        CaseOutRate: caseOutRate ? parseFloat(caseOutRate) : null,
        CaseOutUoM: state.caseOutUoM ? parseInt(state.caseOutUoM) : null,
        CaseOutUoMOther: state.caseOutUoM ? (parseInt(state.caseOutUoM) === SOWEnum.UoM.Other ? state.caseOutUoMOther : null) : null,
        OrderProcessingRate: orderProcessingRate ? parseFloat(orderProcessingRate) : null,
        OrderProcessingUoM: state.orderProcessingUoM ? parseInt(state.orderProcessingUoM) : null,
        OrderProcessingUoMOther: state.orderProcessingUoM
          ? parseInt(state.orderProcessingUoM) === SOWEnum.UoM.Other
            ? state.orderProcessingUoMOther
            : null
          : null,
        GradeAPalletRate: gradeAPalletRate ? parseFloat(gradeAPalletRate) : null,
        GradeAPalletUoM: state.gradeAPalletUoM ? parseInt(state.gradeAPalletUoM) : null,
        GradeAPalletUoMOther: state.gradeAPalletUoM
          ? parseInt(state.gradeAPalletUoM) === SOWEnum.UoM.Other
            ? state.gradeAPalletUoMOther
            : null
          : null,
        ShrinkWrapRate: shrinkWrapRate ? parseFloat(shrinkWrapRate) : null,
        ShrinkWrapUoM: state.shrinkWrapUoM ? parseInt(state.shrinkWrapUoM) : null,
        ShrinkWrapUoMOther: state.shrinkWrapUoM
          ? parseInt(state.shrinkWrapUoM) === SOWEnum.UoM.Other
            ? state.shrinkWrapUoMOther
            : null
          : null,
        WarehouseId: state.warehouseId,
      };
      submitProposalWarehouse(data, history, alert)
        .then((resp) => {
          setSubmitted(false);
          setShowProposal(false);
          closeModal();
        })
        .catch((err) => {
          setSubmitted(false);
          setShowProposal(false);
          closeModal();
        });
    }
  };
  return (
    <div className="max-h-96 overflow-y-auto border-gray-200 px-4 py-5 sm:p-0">
      <div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="col-span-4 md:col-span-4">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Warehouses:
          </label>
          <div className="mt-1">
            <select
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              name="warehouseId"
              value={state.warehouseId}
              onChange={handleChange}>
              <option value="">Select</option>
              {list.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.warehouseName}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Storage:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Rate"
              value={storageRateFormatted}
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setStorageRateFormatted(formattedValue);
                setStorageRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="storageUoM"
            value={state.storageUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.storageUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                placeholder="Storage UoM"
                name="storageUoMOther"
                value={state.storageUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Handling In:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={handlingInRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setHandlingInRateFormatted(formattedValue);
                setHandlingInRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="HandlingInUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="handlingInUoM"
            value={state.handlingInUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.handlingInUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                placeholder="Handling In UoM"
                name="handlingInUoMOther"
                value={state.handlingInUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Handling Out:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={handlingOutRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setHandlingOutRateFormatted(formattedValue);
                setHandlingOutRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="handlingOutUoM"
            value={state.handlingOutUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.handlingOutUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                placeholder="Handling Out UoM"
                name="handlingOutUoMOther"
                value={state.handlingOutUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Case Out:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={caseOutRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setCaseOutRateFormatted(formattedValue);
                setCaseOutRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="caseOutUoM"
            value={state.caseOutUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.caseOutUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                placeholder="Case Out UoM"
                name="caseOutUoMOther"
                value={state.caseOutUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Order Processing:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={orderProcessingRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setOrderProcessingRateFormatted(formattedValue);
                setOrderProcessingRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="orderProcessingUoM"
            value={state.orderProcessingUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.orderProcessingUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                placeholder="Order Processing UoM"
                name="orderProcessingUoMOther"
                value={state.orderProcessingUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Grade A Pallet:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={gradeAPalletRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setGradeAPalletRateFormatted(formattedValue);
                setGradeAPalletRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="gradeAPalletUoM"
            value={state.gradeAPalletUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.gradeAPalletUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                id="scopeName"
                autoComplete="given-name"
                placeholder="Grade A Pallet UoM"
                name="gradeAPalletUoMOther"
                value={state.gradeAPalletUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="sm:col-span-3">
          <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
            Shrink Wrap:
          </label>
          <div className="mt-1">
            <NumberFormat
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={shrinkWrapRateFormatted}
              placeholder="Rate"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setShrinkWrapRateFormatted(formattedValue);
                setShrinkWrapRate(value);
              }}
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="storageUom" className="block text-sm font-medium text-gray-700 mt-6">
            {`\n`}
          </label>
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="shrinkWrapUoM"
            value={state.shrinkWrapUoM}
            onChange={handleChange}>
            <option value="">Select</option>
            {uomList.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}{" "}
              </option>
            ))}
          </select>
        </div>
        {state.shrinkWrapUoM == SOWEnum.UoM.Other ? (
          <div className="col-span-4">
            <div className="mt-1">
              <input
                type="text"
                id="scopeName"
                autoComplete="given-name"
                placeholder="Shrink Wrap UoM"
                name="shrinkWrapUoMOther"
                value={state.shrinkWrapUoMOther}
                onChange={handleChange}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-span-6 sm:col-span-3">
          <div className="grid grid-cols-4">
            <button
              type="button"
              onClick={submitApiCall}
              className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4">
              {submitted ? (
                <SpinnerLottie isOpen={true} />
              ) : (
                <>
                  {" "}
                  Complete
                  <ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitProposal;
