import {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import Warehouse from './components/wareHouse';
import SearchBox from './components/warehouseSearch';
import {getSupplierWarehouse, getSupplierWarehouseList} from '../../../actions';
import Loader from '../CommonModules/loader';

export default function MyWareHouse() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [query, setQuery] = useState('');
	const [isLoader, setIsLoader] = useState(false);
	// const [isOpen, setIsOpen] = useState(false);
	useEffect(() => {
		dispatch(getSupplierWarehouse());
	}, [dispatch]);
	const {warehouses, loading} = useSelector((state) => state.warehouseData);
	const [list, setList] = useState(warehouses ? warehouses : []);
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			const data = findWarehouseMatch(query);
			setList(data);
		}, 500);
		return () => clearTimeout(timeOutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useMemo(() => {
		setList(warehouses);
	}, [warehouses]);

	useEffect(() => {
		setIsLoader(true);
		getSupplierWarehouseList()
			.then((resp) => {
				setList(resp);
				setIsLoader(false);
			})
			.catch((err) => {
				setList([]);
				setIsLoader(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findWarehouseMatch = (name) => {
		if (name !== '') {
			const matchRecords = warehouses.filter((v) =>
				v.warehouseName.toLowerCase().includes(name.toLowerCase())
			);
			return matchRecords;
		} else {
			return warehouses;
		}
	};
	return (
		<Fragment>
			{/* <Loader isLoading={isLoader} /> */}
			<header className="shadow-sm bg-gray-200">
				<SearchBox history={history} setQuery={setQuery} />
			</header>
			<TableWrapperHoc>
				<Warehouse list={list} loader={loading} />
			</TableWrapperHoc>
		</Fragment>
	);
}
