import {Fragment} from 'react';

export default function NotFound() {
	return (
		<Fragment>
			<div className="flex justify-center">
				<span className="flex-1 text-sm text-gray-700 text-center">
					No warehouses that match the category were found. Please refine your search and try again.
				</span>
			</div>
		</Fragment>
	);
}
