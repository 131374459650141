import {Fragment} from 'react';
import WarehouseCard from '../../CommonModules/wareHouseCard';
import {isArrayLengthExist} from '../../../../utils';
import StackWarehouseLoaders from '../../CommonModules/loader/stackWarehouseLoader';
import NotFound from '../../CommonModules/notFound';

export default function WareHouse({list, loader}) {
	return (
		<Fragment>
			<div className="grid p-4 grid-cols-1 md:grid-cols-3 md:p-0 gap-4">
				{loader ? (
					<>
						<StackWarehouseLoaders />
						<StackWarehouseLoaders />
						<StackWarehouseLoaders />
						<StackWarehouseLoaders />
						<StackWarehouseLoaders />
						<StackWarehouseLoaders />
					</>
				) : (
					isArrayLengthExist(list) &&
					list.map((v, i) => {
						return (
							<Fragment key={i}>
								<WarehouseCard {...v} />
							</Fragment>
						);
					})
				)}
			</div>
			{!loader && !isArrayLengthExist(list) && <NotFound />}
		</Fragment>
	);
}
