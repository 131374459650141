import { mainActionTypes } from './actionTypes';
import {
    actionCreator,
    API_BASE_URL
} from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';
import axios from 'axios';
import { findUserRoleRoute, GetTokenDetails } from '../utils'
import { getMySubscriptionAction, hasPaymentMethodAction } from './paymentAction'


export const verifyAction = (email, code, redirectMethod, alert, history, type) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.VERIFY.REQUEST));
        RestClient.get(`${API_BASE_URL}/Account/verifycode?Email=${email}&code=${code}`).then((resp) => {
            if (resp.status === 200) {
                localStorage.setItem('token', resp.data.value.access_token)
                getDropdownDataAPI(resp.data.value.access_token);
                dispatch(getMySubscriptionAction(history));
                dispatch(hasPaymentMethodAction(history, type === 'shipper' ? 'Shipper' : 'Supplier'));
                setTimeout(() => {
                    dispatch(
                        actionCreator(
                            mainActionTypes.VERIFY.SUCCESS,
                            resp.data.value.access_token
                        ),
                    );
                    redirectMethod();
                }, 2600);

            } else {
                dispatch(
                    actionCreator(mainActionTypes.VERIFY.FAILURE)
                );
                if (resp.data && resp.data.value) {
                    alert.show(
                        { title: 'Verification Error', discription: resp.data.value + " Please check your code and try again" },
                        { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                    alert.show(
                        { title: 'Error', discription: resp.data.errors.Password[0] },
                        { type: 'error' })
                }
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.VERIFY.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
        });

    };
};

export const getDropdownDataAction = () => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.DROPDOWN_GETALL.REQUEST));
        RestClient.get(`${API_BASE_URL}/Dropdown/getall`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.DROPDOWN_GETALL.SUCCESS,
                        resp.data
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.DROPDOWN_GETALL.FAILURE)
                );

            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.DROPDOWN_GETALL.FAILURE)
            );

        });

    };
};
/**
 * Fetching dropdown values
 * 
 */

export const getDropdownDataAPI = (d) => {
    const token = 'Bearer ' + d
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/Dropdown/getall`, {
            headers: {
                Authorization: token
            }
        }).then((data) => {
            localStorage.setItem('filters', JSON.stringify(data.data.value));

        }).catch(err => {
            reject(err);
        })
    })
}
export const resenCodeAction = (email, alert) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.RESEND_CODE.REQUEST));
        RestClient.get(`${API_BASE_URL}/Account/resendcode?email=${email}`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.RESEND_CODE.SUCCESS,
                        resp.data.value
                    ),
                );
                alert.show(
                    { title: 'Success', discription: resp.data.value },
                    { type: 'success' })
            } else {
                dispatch(
                    actionCreator(mainActionTypes.RESEND_CODE.FAILURE)
                );
                alert.show(
                    { title: 'Error', discription: resp.data.value },
                    { type: 'error' })
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.RESEND_CODE.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: 'Something went wrong, please try again.' },
                { type: 'error' })
        });

    };
};
export const loginAction = (obj, history, alert, signalObj) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.LOGIN.REQUEST));

        RestClient.post(`${API_BASE_URL}/Account/signin`, JSON.stringify(obj)).then((resp) => {
            if (resp.status === 200) {
                const isFilterExists = localStorage.getItem('filters');
                if (resp.data?.access_token) {
                    localStorage.setItem('token', resp.data?.access_token)
                    const userDetail = GetTokenDetails(resp.data.access_token);
                    dispatch(getMySubscriptionAction(history));
                    //saveOneSignalToken(signalObj)
                    dispatch(hasPaymentMethodAction(history, userDetail.actort));
                    if (!isFilterExists) {
                        getDropdownDataAPI(resp.data.access_token);
                        setTimeout(() => {
                            dispatch(
                                actionCreator(
                                    mainActionTypes.LOGIN.SUCCESS
                                ),
                            );
                            findUserRoleRoute(userDetail.actort, history);
                        }, 2500);
                    } else {
                        dispatch(
                            actionCreator(
                                mainActionTypes.LOGIN.SUCCESS
                            ),
                        );
                        findUserRoleRoute(userDetail.actort, history);
                    }
                } else {
                    alert.show(
                        { title: 'Error', discription: resp.data.Active === false ? 'Please activate your email' : 'Something went wrong' },
                        { type: 'error' })
                }



            } else {
                dispatch(
                    actionCreator(mainActionTypes.LOGIN.FAILURE)
                );
                if (resp.data && resp.data.value) {
                    alert.show(
                        { title: 'Error', discription: resp.data.value },
                        { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                    alert.show(
                        { title: 'Error', discription: resp.data.errors.Password[0] },
                        { type: 'error' })
                }

            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.LOGIN.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
        });

    };
};

export const logoutAction = history => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.LOGOUT.SUCCESS));
        deleteLocalStorageExcept('app_version')
        history.push('/');
    }
}
export const deleteLocalStorageExcept = (key) => {
    const itemValue = localStorage.getItem(key);
    localStorage.clear();
    localStorage.setItem(key, itemValue);
}

export const emptySignupAction = () => {
    return (dispatch) => {
        dispatch(
            actionCreator(mainActionTypes.SIGNUP.FAILURE)
        );
    }
}


/**
 * Fetching old notifications
 * 
 */

export const getNotificationAPI = () => {
    const token = 'Bearer ' + localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/api/Notification/GetMyNotifications`, {
            headers: {
                Authorization: token
            }
        }).then((data) => {
            resolve(data.data.value)

        }).catch(err => {
            reject([]);
        })
    })
}

export const readMotificationAPI = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/api/Notification/MarkRead`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * forgot password Account/forgotpassword
 */

export const forgotPassword = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Account/forgotpassword`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}

/**Reset Password /Account/resetpassword*/
export const resetPassword = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Account/resetpassword`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}
// save oneSignal Id token into DB
export const saveOneSignalToken = (tokenObj) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Token/SaveToken`, JSON.stringify(tokenObj)).then((resp) => {
            //

            //
        })
    })
}