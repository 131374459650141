/* eslint-disable jsx-a11y/anchor-is-valid */
import {useSelector} from 'react-redux';
import {isArrayLengthExist} from '../../../../utils';
import {getContractDeatil} from '../../../../actions';
import Modal from '../../CommonModules/modals';
import {Fragment, useState} from 'react';
import ShipperModal from '../../CommonModules/modals/shipperModal';
import Lottie from 'react-lottie';
import threeDots from '../../../../assets/anim/threedots_pulse.json';

export default function ActiveContracts() {
	const {contracts} = useSelector((state) => state.warehouseData);
	const [isOpen, setIsOpen] = useState(false);
	const [contractDetail, setContractDetail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [sowId, setSowId] = useState(null);
	const [scopeLoc, setScopeLoc] = useState(null);

	const handleContractDetail = (id, name, sow_id) => {
		setContractDetail(null);
		setSowId(sow_id);
		getContractDeatil(id)
			.then((resp) => {
				setIsOpen(true);
				setContractDetail(resp);
				setIsLoading(true);
				setScopeLoc(name);
			})
			.catch((err) => {
				setIsOpen(false);
				setContractDetail(null);
				setIsLoading(false);
				setSowId(null);
				setScopeLoc(null);
			});
	};
	const closeModal = (val) => {
		setIsOpen(val);
		setSowId(null);
		setIsLoading(false);
		setScopeLoc(null);
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: threeDots,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<Fragment>
			{isLoading && isOpen && (
				<Modal
					isOpen={isOpen}
					setOpen={closeModal}
					modalTitle={'Shipper Detail'}
					modalSubTitle={`${scopeLoc}`}
				>
					<ShipperModal val={contractDetail} isLoading={false} />
				</Modal>
			)}
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							{' '}
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Submitted Scope
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Status
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											<span className="sr-only">Contact Details</span>
											Contact Details
										</th>
									</tr>
								</thead>
								<tbody>
									{isArrayLengthExist(contracts)
										? contracts.map((val, index) => (
												<tr
													key={index}
													className={
														index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
													}
												>
													<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
														{val.scopeName || ''}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{'Active'}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
														{!isOpen && sowId === val.proposalId ? (
															<Lottie
																isClickToPauseDisabled={true}
																height={24}
																width={90}
																options={defaultOptions}
															/>
														) : (
															<a
																onClick={() =>
																	handleContractDetail(
																		val.shipperId,
																		val.scopeName,
																		val.proposalId
																	)
																}
																className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
															>
																{val.warehouseName || ''}
															</a>
														)}
													</td>
												</tr>
										  ))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
