import NumberFormat from 'react-number-format';
import {ArrowSmRightIcon, ArrowSmLeftIcon} from '@heroicons/react/outline';
export default function Commodity(props) {
	const {
		uomConst,
		handleChange,
		dimensionConst,
		weightConst,
		validateCommodity,
		state,
		validateBack,
		productWeightFormatted,
		setProductWeightFormatted,
		setProductWeight,
	} = props;
	return (
		<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
			<div className="sm:col-span-3">
				<label
					htmlFor="commodity"
					className="block text-sm font-medium text-gray-700"
				>
					Commodity
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="commodity"
						id="commodity"
						autoComplete="given-name"
						value={state.commodity}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
				</div>
			</div>

			<div className="col-span-6 sm:col-span-3">
				<label
					htmlFor="country"
					className="block text-sm font-medium text-gray-700"
				>
					Box
				</label>
				<select
					className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="productUoM"
					value={state.productUoM}
					onChange={(e) => handleChange(e)}
				>
					<option value="">Select Product Unit of Measure</option>
					{uomConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="sm:col-span-6">
				<label
					htmlFor="totalSKU"
					className="block text-sm font-medium text-gray-700"
				>
					Total SKU
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="totalSKU"
						id="commodity"
						maxLength={6}
						autoComplete="given-name"
						placeholder={'Total SKU'}
						value={state.totalSKU}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
				</div>
			</div>
			<div className="sm:col-span-3">
				<label
					htmlFor="productDimensions"
					className="block text-sm font-medium text-gray-700"
				>
					Product Dimensions:
				</label>
				<div className="flex flex-row mt-1 space-x-2">
					<input
						type="text"
						name="productDimensions"
						id="productDimensions"
						autoComplete="given-name"
						value={state.productDimensions}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
					<span className="flex items-center text-sm text-gray-300">X</span>
					<input
						type="text"
						name="productDimensions2"
						id="productDimensions2"
						autoComplete="given-name"
						value={state.productDimensions2}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<label
					htmlFor="productDimensionsType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="productDimensionsType"
					value={state.productDimensionsType}
					onChange={handleChange}
				>
					<option value="">Select</option>
					{dimensionConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="sm:col-span-3">
				<label
					htmlFor="productDimensions"
					className="block text-sm font-medium text-gray-700"
				>
					Product Weight:
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						value={productWeightFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setProductWeightFormatted(formattedValue);
							setProductWeight(value);
						}}
					/>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<label
					htmlFor="productWeightType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="productWeightType"
					value={state.productWeightType}
					onChange={handleChange}
				>
					<option value="">Select</option>
					{weightConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateBack(e, 0)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						Previous
					</button>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateCommodity(e)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						Next
						<ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
}
