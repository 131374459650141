import {Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import * as SOWEnum from '../../../../utils/sow.enum';
import {addCommas} from '../../../../utils';

export default function ProposalModal({val, triggerMethod}) {
	const location = useLocation();
	
//
	return (
		<Fragment>
			{val ? (
				<div className="border-t max-h-96 overflow-y-auto border-gray-200 px-4 py-5 sm:p-0">
					<dl className="sm:divide-y sm:divide-gray-200">
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">
								Warehouse Name{' '}
							</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.warehouseName ? val.warehouseName : '-'}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Storage</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.storageRate &&
									val.storageRate > 0 &&
									val.storageUoM &&
									addCommas(val.storageRate)}{' '}
								{val.storageUoM ? 'per' : ''}{' '}
								{val.storageUoM === SOWEnum.UoM.Other && val.storageUoMOther
									? val.storageUoMOther
									: SOWEnum.UoMLabel.get(val.storageUoM)}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Handling In</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.handlingInRate &&
									val.handlingInRate > 0 &&
									val.handlingInUoM &&
									addCommas(val.handlingInRate)}{' '}
								{val.handlingInUoM ? 'per' : ''}{' '}
								{val.handlingInUoM === SOWEnum.UoM.Other &&
								val.handlingInUoMOther
									? val.handlingInUoMOther
									: SOWEnum.UoMLabel.get(val.handlingInUoM)}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">
								Handling Out{' '}
							</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.handlingOutRate &&
									val.handlingOutRate > 0 &&
									val.handlingOutUoM &&
									addCommas(val.handlingOutRate)}{' '}
								{val.handlingOutUoM ? 'per' : ''}{' '}
								{val.handlingOutUoM === SOWEnum.UoM.Other &&
								val.handlingOutUoMOther
									? val.handlingOutUoMOther
									: SOWEnum.UoMLabel.get(val.handlingOutUoM)}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Case Out</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.caseOutRate &&
									val.caseOutRate > 0 &&
									val.caseOutUoM &&
									addCommas(val.caseOutRate)}{' '}
								{val.caseOutUoM ? 'per' : ''}{' '}
								{val.caseOutUoM === SOWEnum.UoM.Other && val.caseOutUoMOther
									? val.caseOutUoMOther
									: SOWEnum.UoMLabel.get(val.caseOutUoM)}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">
								Grade A Pallet
							</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.orderProcessingRate &&
									val.orderProcessingRate > 0 &&
									val.orderProcessingUoM &&
									addCommas(val.orderProcessingRate)}{' '}
								{val.orderProcessingUoM ? 'per' : ''}{' '}
								{val.orderProcessingUoM === SOWEnum.UoM.Other &&
								val.orderProcessingUoMOther
									? val.orderProcessingUoMOther
									: SOWEnum.UoMLabel.get(val.orderProcessingUoM)}
							</dd>
						</div>
						<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Shrink Wrap</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{val.shrinkWrapRate &&
									val.shrinkWrapRate > 0 &&
									val.shrinkWrapUoM &&
									addCommas(val.shrinkWrapRate)}{' '}
								{val.shrinkWrapUoM ? 'per' : ''}{' '}
								{val.shrinkWrapUoM === SOWEnum.UoM.Other &&
								val.shrinkWrapUoMOther
									? val.shrinkWrapUoMOther
									: SOWEnum.UoMLabel.get(val.shrinkWrapUoM)}
							</dd>
						</div>
					</dl>
				</div>
			) : null}
			{location && location.pathname === '/contracts-and-proposals' ? null : (
				<div className="mt-5 sm:mt-6 grid grid-flow-col grid-cols-4 gap-4">
					<button
						type="button"
						className="col-start-1 col-end-3 md:col-start-3 inline-flex justify-center w-full rounded-md border-indigo-600 shadow-sm px-4 py-2 text-base font-medium text-indigo-600 border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
						onClick={() => triggerMethod(2)}
					>
						{'Declined'}
					</button>
					<button
						type="button"
						className="col-start-3 col-end-5 md:col-start-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
						onClick={() => triggerMethod(1)}
					>
						{'Approved'}
					</button>
				</div>
			)}
		</Fragment>
	);
}
