import {Fragment, useState, useRef, useMemo} from 'react';
import SpinnerLottie from '../../CommonModules/spinnerLottie';
import {EyeIcon, TrashIcon, DuplicateIcon} from '@heroicons/react/outline';
import {Transition, Popover} from '@headlessui/react';
import ScopeDetails from './scopeDetails';
import {
	viewScopeDetails,
	duplicateScope,
	getAllSowsShipper,
} from '../../../../actions/shipperAction';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

export default function DeleteScopes({scopes, deleteScopeMethod, isDeleting}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const inputRef = useRef(null);
	const [fragment, setFragment] = useState(false);
	const [isShowing, setIsShowing] = useState(false);
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [scopeDetails, setScopeDetails] = useState(null);
	const [duplicateScopeName, setDuplicateScopeName] = useState('');
	const [errmsg, setErrmsg] = useState(false);

	function showScopeDetails(scopeID) {
		viewScopeDetails(scopeID)
			.then((response) => {
				setIsShowing(true);
				setScopeDetails(response.data.value);
			})
			.catch((err) => {
				console.log(err);
				setIsShowing(false);
			});
	}

	const duplicateScopeMethod = (scopeID, scopeName) => {
		if (scopeName) {
			setErrmsg(false);
			setIsDuplicate(true);
			duplicateScope(scopeID, scopeName)
				.then((response) => {
					dispatch(getAllSowsShipper(history));
					setDuplicateScopeName('');
					setIsDuplicate(false);
					inputRef.current.click();
				})
				.catch((err) => {
					setIsDuplicate(false);
					console.log(err);
				});
		} else {
			setErrmsg(true);
		}
	};
	function truncate(input) {
		if (input.length > 35) {
			return input.substring(0, 35) + '...';
		}
		return input;
	}
	return (
		<Fragment ref={inputRef}>
			<Transition
				show={!isShowing}
				enter="transition-opacity duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				appear={true}
			>
				{/* Add react tabs here to toggle view of scope detail */}
				<div className="border-gray-200 px-4 py-5 sm:p-0">
					{scopes && scopes.length > 0
						? scopes.map((v, i) => {
								return (
									<Fragment key={i}>
										<div
											className="hover:bg-gray-100 border-t max-h-96 border-gray-200 px-4 py-5 sm:p-0 group relative"
											onMouseEnter={() => setFragment(true)}
											onMouseLeave={() => setFragment(false)}
										>
											<dl className="sm:divide-y sm:divide-gray-200">
												<div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
													<dt className="text-sm font-medium text-gray-500 col-span-3">
														{v.scopeName || ''}
													</dt>
													<button
														onClick={() => showScopeDetails(v.id)}
														className="hidden group-hover:flex bg-white col-span-1 justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md bg-border text-yellow-600 hover:text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
													>
														{isDeleting === v.id ? (
															<SpinnerLottie isOpen={true} />
														) : (
															<div className="flex flex-row items-center">
																<EyeIcon className="h-5 mr-2"></EyeIcon>View
															</div>
														)}
													</button>
													<Popover as={Fragment}>
														<Popover.Button
															className="hidden group-hover:flex col-span-1 justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md bg-border bg-yellow-600 text-yellow-600 bg-opacity-10 hover:text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
															// onClick={() => duplicateScopeMethod(v.id, "")}
														>
															<DuplicateIcon className="h-5 mr-2"></DuplicateIcon>
															Duplicate
														</Popover.Button>
														<Transition
															as={Fragment}
															enter="transition ease-out duration-200"
															enterFrom="opacity-0 translate-y-1"
															enterTo="opacity-100 translate-y-0"
															leave="transition ease-in duration-150"
															leaveFrom="opacity-100 translate-y-0"
															leaveTo="opacity-0 translate-y-1"
														>
															<Popover.Panel className="absolute z-10 left-3/4 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
																<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
																	<div className="relative grid py-4 px-6 gap-2 bg-yellow-50">
																		{/* if char count more than 52 add ... */}
																		<label
																			for="duplicateNew"
																			className="block text-sm font-medium text-gray-700 flex-wrap"
																		>
																			Duplicate "
																			{`${
																				duplicateScopeName === ''
																					? v.scopeName
																					: truncate(duplicateScopeName)
																			}`}
																			" to a new scope.
																		</label>
																		<input
																			type="text"
																			name="duplicateNew"
																			id="duplicateNew"
																			className="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md"
																			placeholder="Enter New Name"
																			value={duplicateScopeName}
																			onChange={(e) =>
																				setDuplicateScopeName(e.target.value)
																			}
																		/>
																		{errmsg ? (
																			<p className="block text-sm font-medium text-red-700 flex-wrap">
																				Please enter the scope name
																			</p>
																		) : (
																			''
																		)}
																		<div
																			className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
																			onClick={() =>
																				duplicateScopeMethod(
																					v.id,
																					duplicateScopeName
																				)
																			}
																		>
																			{isDuplicate ? (
																				<SpinnerLottie isOpen={true} />
																			) : (
																				'Duplicate'
																			)}
																		</div>
																	</div>
																</div>
															</Popover.Panel>
														</Transition>
													</Popover>
													<button
														className="hidden group-hover:flex col-span-1 justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md bg-border bg-red-600 text-red-600 bg-opacity-10 hover:text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
														onClick={(e) => deleteScopeMethod(e, v.id)}
													>
														{isDeleting === v.id ? (
															<SpinnerLottie isOpen={true} />
														) : (
															<div className="flex flex-row items-center">
																<TrashIcon className="h-5 mr-2"></TrashIcon>
																Delete Scope
															</div>
														)}
													</button>
												</div>
											</dl>
										</div>
									</Fragment>
								);
						  })
						: null}
				</div>
			</Transition>
			<ScopeDetails
				show={isShowing ? true : false}
				records={scopeDetails}
				goBack={() => setIsShowing(false)}
				showScopeDetails={showScopeDetails}
			></ScopeDetails>
		</Fragment>
	);
}
