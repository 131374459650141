import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {resetPassword} from '../../../actions';
import popLogo from '../../../assets/brand/poplogo.svg';
import Lottie from 'react-lottie';
import loaderAnim from '../../../assets/anim/loader_white.json';
import {useAlert} from 'react-alert';
import {InformationCircleIcon} from '@heroicons/react/outline';

const Reset = () => {
	const history = useHistory();
	const params = useParams();
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [userid, setUserid] = useState('');
	const [token, setToken] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const alert = useAlert();
	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (
			newPassword &&
			confirmPassword &&
			newPassword.length >= 6 &&
			/^(?=.*[A-Z])/.test(newPassword) &&
			confirmPassword === newPassword
		) {
			const data = {
				UserId: userid,
				Password: newPassword,
				Token: token,
			};
			setSubmitted(false);
			resetPassword(data)
				.then((resp) => {
					if (resp.statusCode !== null && resp.statusCode === 400) {
						alert.show(
							{
								title: 'Error',
								discription: resp.value,
							},
							{type: 'error'}
						);
					} else {
						alert.show(
							{
								title: 'Success',
								discription: resp,
							},
							{type: 'success'}
						);
						history.push('/');
					}
				})
				.catch((err) => {
					alert.show(
						{
							title: 'Error',
							discription: err.message,
						},
						{type: 'error'}
					);
				});
		}
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loaderAnim,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const goBack = () => {
		history.push('/forgot-password');
	};
	useEffect(() => {
		
//
		setUserid(params.id);
		setToken(params.token);
	}, []);
	
//
	return (
		<div className="min-h-screen bg-indigo-900 flex">
			<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div className="flex items-center flex-col mb-16">
						<img className="h-8 w-auto" src={popLogo} alt="Workflow" />
					</div>

					<div className="mt-8">
						<div className="mt-6">
							<div className="justify-between space-y-6 flex flex-col">
								<div>
									<label
										htmlFor="newPassword"
										className="block text-sm font-medium text-gray-200"
									>
										New Password
									</label>
									<div className="mt-1 relative">
										<input
											id="newPassword"
											name="newPassword"
											type="newPassword"
											autoComplete="newPassword"
											value={newPassword}
											onChange={(e) => {
												e.preventDefault();
												setNewPassword(e.target.value);
											}}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>

										{submitted && !newPassword && (
											<span className="flex flex-row text-sm font-medium text-white bg-red-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
												<InformationCircleIcon className="push w-4 inline-block mr-1" />
												<span className="h-5">Required</span>
											</span>
										)}

										{submitted &&
											newPassword &&
											newPassword.length < 5 &&
											!/^(?=.*[A-Z])/.test(newPassword) && (
												<div className="block text-sm font-medium text-red-500">
													Password should be minimum 6 characters
												</div>
											)}

										{submitted &&
											newPassword &&
											newPassword.length > 5 &&
											!/^(?=.*[A-Z])/.test(newPassword) && (
												<div className="block text-sm font-medium text-red-500">
													Password should be one capital and one special
												</div>
											)}
									</div>
								</div>
								<div>
									<label
										htmlFor="confirmPassword"
										className="block text-sm font-medium text-gray-200"
									>
										Confirm Password
									</label>
									<div className="mt-1 relative">
										<input
											id="confirmPassword"
											name="confirmPassword"
											type="confirmPassword"
											autoComplete="confirmPassword"
											value={confirmPassword}
											onChange={(e) => {
												e.preventDefault();
												setConfirmPassword(e.target.value);
											}}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>

										{submitted && !confirmPassword && (
											<span className="flex flex-row text-sm font-medium text-white bg-red-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
												<InformationCircleIcon className="push w-4 inline-block mr-1" />
												<span className="h-5">Required</span>
											</span>
										)}
										{submitted && confirmPassword !== newPassword && (
											<span className="flex flex-row text-sm font-medium text-white bg-red-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
												<InformationCircleIcon className="push w-4 inline-block mr-1" />
												<span className="h-5">Password has not match</span>
											</span>
										)}
									</div>
								</div>

								<div className="">
									<button
										type="submit"
										onClick={goBack}
										className="w-full flex justify-center py-2 px-4 border-white border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-white hover:bg-opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-6 border-2 mb-4"
									>
										Back to Forgot Password Screen
									</button>
									<button
										type="submit"
										onClick={handleSubmit}
										className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{loading ? (
											<Lottie
												isClickToPauseDisabled={true}
												height={26}
												width={140}
												options={defaultOptions}
											/>
										) : (
											'Submit'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden lg:block relative w-0 flex-1">
				<img
					className="absolute inset-0 h-full w-full object-cover"
					src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
					alt=""
				/>
			</div>
		</div>
	);
};
export default React.memo(Reset);
