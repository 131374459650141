import jwtDecode from "jwt-decode";

export function isTokenAlive(tokenDecoded) {
    const now = Date.now().valueOf() / 1000;
    if (typeof tokenDecoded.exp === 'undefined' || tokenDecoded.exp < now) {
        return false
    }
    return true;
}

export function GetTokenDetails(token) {
    if (token) {
        return jwtDecode(token)
    }
    return null;
}
export function isUserAuthenticated(token) {
    const user = GetTokenDetails(token);
    if (user) {
        return isTokenAlive(user);
    }
    else {
        return false;
    }
}


export const GOOGLE_API_KEY = 'AIzaSyDnWmWoB0H0h75KYj3ZEbDd4Exwy4MLGh8';