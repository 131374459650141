export default function AlertTemplate({options, message, close, style}) {
	return (
		<div
			style={{...style}}
			className={
				options.type === 'error'
					? 'relative overflow-hidden z-50 mx-2 mt-2 bg-red-50 shadow rounded-md p-4 transition-opacity ease-linear duration-300'
					: 'relative overflow-hidden z-50 mx-2 mt-2 bg-green-50 shadow rounded-md p-4 transition-opacity ease-linear duration-300'
			}
		>
			<div className="flex">
				<div className="flex-shrink-0">
					{options.type === 'error' ? (
						<svg
							className="h-5 w-5 text-red-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							/>
						</svg>
					) : (
						<svg
							className="h-5 w-5 text-green-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
								clipRule="evenodd"
							/>
						</svg>
					)}
				</div>
				<div className="ml-3">
					<h3
						className={
							options.type === 'error'
								? 'text-sm font-medium text-red-800'
								: 'text-sm font-medium text-green-800'
						}
					>
						{message.title}
					</h3>
					<p
						className={
							options.type === 'error'
								? 'mt-2 text-sm text-red-800'
								: 'mt-2 text-sm text-green-800'
						}
					>
						{message.discription}
					</p>
				</div>
				<div className="ml-auto pl-3">
					<div className="-mx-1.5 -my-1.5">
						<button
							onClick={close}
							className={
								options.type === 'error'
									? 'hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600 inline-flex bg-red-50 rounded-md p-1.5 text-red-500'
									: 'hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-green-600 inline-flex bg-green-50 rounded-md p-1.5 text-green-500'
							}
						>
							<span className="sr-only">Dismiss</span>
							<svg
								className="h-5 w-5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				className={
					options.type === 'error'
						? 'absolute h-1 w-0 bg-red-500 bottom-0 left-0 transition-all ease-linear duration-4000'
						: 'absolute h-1 w-0 bg-green-500 bottom-0 left-0 transition-all ease-linear duration-4000'
				}
			></div>
		</div>
	);
}
