import { mainActionTypes } from './actionTypes';
import {
    actionCreator,
    API_BASE_URL
} from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';
import { logoutAction } from './userAction'


export const getAllSowsShipper = (history) => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/Shipper/getallsows`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_ALL_SOWS.SUCCESS,
                        resp.data.value && resp.data.value.soWs
                    ),
                );
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_ALL_SOWS.FAILURE)
                );
            }

        }).catch((err) => {
            console.warn(JSON.stringify(err))
            dispatch(
                actionCreator(mainActionTypes.GET_ALL_SOWS.FAILURE)
            );
        });

    };
};

export const scopeCreatedAction = (requestObj, history, internalPage) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.SCOPE_CREATED.REQUEST));
        RestClient.post(`${API_BASE_URL}/Shipper/createsow`, JSON.stringify(requestObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.SCOPE_CREATED.SUCCESS,
                        resp.data
                    ),
                );
                if (internalPage === false) {
                    history.push('/explore-warehouses')
                } else {
                    localStorage.setItem('lockPage', 'true');
                }
                dispatch(getAllSowsShipper(history))

            } else {
                dispatch(
                    actionCreator(mainActionTypes.SCOPE_CREATED.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.SCOPE_CREATED.FAILURE)
            );
        });

    };
};
/**
 * 
 * @param {*} scopeId 
 * @param {*} history 
 * @returns 
 */
export const filterWarehouseByScopeId = (scopeId, history) => {
    return (dispatch) => {
        dispatch(
            actionCreator(mainActionTypes.FILTER_BY_SCOPE.REQUEST)
        );
        RestClient.get(`${API_BASE_URL}/Shipper/searchbyscope?ScopeId=${scopeId}`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.FILTER_BY_SCOPE.SUCCESS,
                        resp.data.value && resp.data.value.warehouses
                    ),
                );
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.FILTER_BY_SCOPE.FAILURE)
                );
            }

        }).catch((err) => {
            console.warn(JSON.stringify(err))
            dispatch(
                actionCreator(mainActionTypes.FILTER_BY_SCOPE.FAILURE)
            );
        });

    };
};

/**
 * 
 * @param {*} filterObj 
 * @param {*} history 
 * @returns 
 */
export const filterWarehouseByType = (filterObj, history) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.FILTER_BY_TYPE.REQUEST));
        RestClient.post(`${API_BASE_URL}/Shipper/explorewarehouses`, JSON.stringify(filterObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.FILTER_BY_TYPE.SUCCESS,
                        resp.data && resp.data.warehouses
                    ),
                );
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.FILTER_BY_TYPE.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.FILTER_BY_TYPE.FAILURE)
            );
        });

    };
};

export const pairWithWarehouse = (scopeId, warehouseId) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Proposal/SaveProposal`, JSON.stringify({
            "scopeId": scopeId,
            "warehouseId": warehouseId
        })).then((resp) => {
            if (resp.status === 200) {
                resolve(resp)
            } else {
                reject(resp)
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

/**
 *
 * Shipper registeration
 *
 */
export const shipperRegisteration = (requestObj, alert) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.SIGNUP.REQUEST));
        RestClient.post(`${API_BASE_URL}/Account/registershipper`, JSON.stringify(requestObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.SIGNUP.SUCCESS,
                        resp.data
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.SIGNUP.FAILURE)
                );
                if (resp.data && resp.data.value) {
                    alert.show(
                        { title: 'Error', discription: resp.data.value },
                        { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                    alert.show(
                        { title: 'Error', discription: 'Something went wrong,Please try again later' },
                        { type: 'error' })
                }
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.SIGNUP.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
        });

    };
}


/**
 * 
 * @param {*} history 
 * @returns 
 */
export const pairwarehouseList = (history) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.FILTER_BY_TYPE.REQUEST));
        RestClient.get(`${API_BASE_URL}/api/Shipper/GetMyPairedWarehouse`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.FILTER_BY_TYPE.SUCCESS,
                        resp.data && resp.data.value && resp.data.value.warehouses
                    ),
                );
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.FILTER_BY_TYPE.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.FILTER_BY_TYPE.FAILURE)
            );
        });

    };
};

export const deleteScopeActionMethod = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.delete(`${API_BASE_URL}/api/Shipper/archivesow?id=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve('true')
            } else {
                reject(resp.data.errors)
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const viewScopeDetails = (scopeId) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/api/Shipper/getsowbyid?id=${scopeId}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}

/**
 * duplicate Sow action creator
 * @param {*} scopeId 
 * @param {*} scopeName 
 * @returns 
 */
export const duplicateScope = (scopeId, scopeName) => {
    const obj = {
        "sowId": scopeId,
        "sowName": scopeName
    }

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/api/Shipper/duplicatesow`, obj).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}

export const updateSow = (scopeId, editObj) => {

    return new Promise((resolve, reject) => {
        RestClient.put(`${API_BASE_URL}/api/Shipper/editsow?id=${scopeId}`, editObj).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}