import {Fragment, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import HeaderBox from './components/subHeader';
import {
	proposalsConstant,
	activeContracts,
	myproposalsConstant,
} from '../../../constants';
import {getSupplierContracts, getSupplierProposals} from '../../../actions';

import ActiveContracts from './components/activeContracts';
import MyProposals from './components/myProposals';
import Loader from '../CommonModules/loader';

export default function Proposals() {
	const dispatch = useDispatch();
	const {loading} = useSelector((state) => state.shipperData);
	const {proposals} = useSelector((state) => state.warehouseData);
	const [ctHeader, setCtHeader] = useState(activeContracts);
	const headerctHeader = (e, val) => {
		e.preventDefault();
		setCtHeader(val);
	};
	useMemo(() => {
		if (ctHeader.toLowerCase() === activeContracts.toLowerCase()) {
			dispatch(getSupplierContracts(true));
		} else if (ctHeader.toLowerCase() === myproposalsConstant.toLowerCase()) {
			dispatch(getSupplierProposals(true));
		}
	}, [ctHeader, dispatch]);

	return (
		<Fragment>
			<Loader isLoading={loading} />
			<header className="shadow-sm bg-gray-200">
				<HeaderBox ctHeader={ctHeader} headerctHeader={headerctHeader} />
			</header>
			<TableWrapperHoc>
				{ctHeader.toLowerCase() === activeContracts.toLowerCase() ? (
					<ActiveContracts />
				) : ctHeader.toLowerCase().includes(proposalsConstant.toLowerCase()) ? (
					<MyProposals list={proposals} />
				) : null}
			</TableWrapperHoc>
		</Fragment>
	);
}
