import {useState, useMemo, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import ActiveContracts from './components/activeContracts';
import MyProposals from './components/myProposals';
import ImcomingContracts from './components/incomingContracts';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import ContractHeader from './components/contractHeader';
import {
	activeContracts,
	inComingContracts,
	sentProposals,
} from '../../../constants';
import {
	getSupplierContracts,
	getSupplierProposals,
	getSupplierIncomingProposals,
	getSupplierWarehouse,
} from '../../../actions';

export default function Contracts() {
	const dispatch = useDispatch();
	const [ctHeader, setCtHeader] = useState(activeContracts);
	const headerctHeader = (e, val) => {
		e.preventDefault();
		setCtHeader(val);
	};
	useMemo(() => {
		if (ctHeader.toLowerCase() === activeContracts.toLowerCase()) {
			dispatch(getSupplierContracts());
		} else if (ctHeader.toLowerCase() === sentProposals.toLowerCase()) {
			dispatch(getSupplierProposals());
		} else if (ctHeader.toLowerCase() === inComingContracts.toLowerCase()) {
			dispatch(getSupplierWarehouse());
			dispatch(getSupplierIncomingProposals());
		}
	}, [ctHeader, dispatch]);
	return (
		<Fragment>
			<header className="shadow-sm bg-gray-200">
				<ContractHeader ctHeader={ctHeader} headerctHeader={headerctHeader} />
			</header>
			<TableWrapperHoc>
				{ctHeader.toLowerCase() === activeContracts.toLowerCase() ? (
					<ActiveContracts />
				) : ctHeader.toLowerCase() === sentProposals.toLowerCase() ? (
					<MyProposals />
				) : ctHeader.toLowerCase() === inComingContracts.toLowerCase() ? (
					<ImcomingContracts />
				) : null}
			</TableWrapperHoc>
		</Fragment>
	);
}
