import { mainActionTypes } from './actionTypes';
import {
    actionCreator,
    API_BASE_URL
} from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';
import { logoutAction } from './userAction'


export const exploreShippersAction = (signupObj) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.EXPLORE_SHIPPERS.REQUEST));
        RestClient.post(`${API_BASE_URL}/Supplier/exploreshippers`, JSON.stringify(signupObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.EXPLORE_SHIPPERS.SUCCESS,
                        resp.data
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.EXPLORE_SHIPPERS.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.EXPLORE_SHIPPERS.FAILURE)
            );
        });

    };
};

export const getsowbyidShippersAction = (id) => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/Shipper/getsowbyid?Id=${id}`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_SOW_ID.SUCCESS,
                        resp.data.value
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_SOW_ID.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.GET_SOW_ID.FAILURE)
            );
        });

    };
};

export const getsowbyidShipper = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/Shipper/getsowbyid?Id=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors && resp.data.errors.id[0])
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const getSupplierContracts = (type = null) => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/${type === true ? 'Shipper' : 'Supplier'}/getcontracts`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_CONTRACTS.SUCCESS,
                        resp.data.value.contracts
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_CONTRACTS.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.GET_CONTRACTS.FAILURE)
            );
        });

    };
};

export const getSupplierProposals = (type = null) => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/${type === true ? 'Shipper' : 'Supplier'}/getproposals`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_PROPOSALS.SUCCESS,
                        resp.data.value.proposals
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_PROPOSALS.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.GET_PROPOSALS.FAILURE)
            );
        });

    };
};

export const getSupplierIncomingProposals = () => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/Proposal/GetIncomingProposal`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_INCOMING_PROPOSALS.SUCCESS,
                        resp.data.value
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_INCOMING_PROPOSALS.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.GET_INCOMING_PROPOSALS.FAILURE)
            );
        });

    };
};

export const getProposalByIdService = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/Supplier/getproposalbyid?Id=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors && resp.data.errors.id[0])
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const getContractDeatil = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/Account/getcontactinfo?UserId=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors && resp.data.errors.id[0])
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const getSupplierWarehouse = () => {

    return (dispatch) => {
        dispatch(
            actionCreator(mainActionTypes.GET_WAREHOUSE_BY_USER.REQUEST)
        );
        RestClient.get(`${API_BASE_URL}/Warehouse/getbysupplier`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_WAREHOUSE_BY_USER.SUCCESS,
                        resp.data.value.warehouses
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_WAREHOUSE_BY_USER.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.GET_WAREHOUSE_BY_USER.FAILURE)
            );
        });

    };
};
export const getSupplierWarehouseList = () => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/Warehouse/getbysupplier`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value.warehouses)
            } else if (resp.status === 400) {
                reject([])
            }
        }).catch((err) => {
            console.warn(err);
            reject([])
        });

    })
};
/**
 * 
 * @param {warehouseId} warehouseId 
 * @param {*} history 
 * it fetches the  warehouse by Id and oth parameters are required
 */
export const getWareHouseById = (warehouseId, history) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/Warehouse/getmywarehousebyid?id=${warehouseId}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else if (resp.status === 400) {
                reject(resp.data.errors && resp.data.errors.id[0])
            } else if (resp.status === 401) {
                logoutAction(history);
            }

        }).catch((err) => {
            reject(err)
        });

    })
};


export const addRemoveService = (formData, history) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Photosphere/addremove`, formData, true).then((resp) => {
            if (resp.status === 200) {
                resolve(resp)
            } else if (resp.status === 400) {
                reject(resp.data.errors && resp.data.errors.WarehouseId[0])
            } else if (resp.status === 401) {
                logoutAction(history);
            }
        }).catch((err) => {
            reject(err)
        });

    })
};


export const updateWarehouseDetail = (formData, history,) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Warehouse/update`, formData, true).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.value)
            } else if (resp.status === 400) {
                reject(resp.data.errors && resp.data.errors.WarehouseId[0])
            } else if (resp.status === 401) {
                logoutAction(history);
            }
        }).catch((err) => {
            reject(err)
        });

    })
};

/**
 *
 * supplier registeration
 *
 */
export const supplierRegisteration = (requestObj, alert) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.SIGNUP.REQUEST));
        RestClient.post(`${API_BASE_URL}/Account/registersupplier`, JSON.stringify(requestObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.SIGNUP.SUCCESS,
                        resp.data
                    ),
                );
            } else {
                dispatch(
                    actionCreator(mainActionTypes.SIGNUP.FAILURE)
                );
                if (resp.data && resp.data.value) {
                    alert.show(
                        { title: 'Error', discription: resp.data.value },
                        { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                    alert.show(
                        { title: 'Error', discription: 'Something went wrong,Please try again later' },
                        { type: 'error' })
                }
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.SIGNUP.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
        });

    };
}
/**
 * 
 * @param {*} formData 
 * @param {*} history 
 * @returns 
 */
export const addWarehouseDetail = (formData, history) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Warehouse/create`, formData, true).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data)
            } else if (resp.status === 400) {
                reject(resp.data.errors && resp.data.errors.WarehouseId[0])
            } else if (resp.status === 401) {
                logoutAction(history);
            }
        }).catch((err) => {
            reject(err)
        });

    })
};


export const submitProposalMethod = (obj) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/api/Shipper/updateproposalstatus`, obj).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors && resp.data.errors.id[0])
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const deletWarehouseMethod = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.delete(`${API_BASE_URL}/api/Warehouse/delete?id=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve('true')
            } else {
                reject(resp.data.errors)
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const submitProposalWarehouse = (data, history, alert) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Supplier/submitproposal`, data, false).then((resp) => {
            // 
//
            if (resp.status === 200) {
                alert.show(
                    { title: 'Success', discription: resp.data },
                    { type: 'success' })
                resolve(resp.data.value)
            } else if (resp.status === 400) {
                alert.show(
                    { title: 'Error', discription: resp.data.value },
                    { type: 'error' })
                reject(resp.data.value)
            } else if (resp.status === 401) {
                logoutAction(history);
            }
        }).catch((err) => {
            reject(err)
        });

    })
};