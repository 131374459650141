import { mainActionTypes } from './actionTypes';
import {
    actionCreator,
    API_BASE_URL
} from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';
import { logoutAction } from './userAction'
import moment from 'moment'

//GET_MY_SUBSCRIPTION
export const getMySubscriptionAction = (history) => {

    return (dispatch) => {
        dispatch(
            actionCreator(mainActionTypes.GET_MY_SUBSCRIPTION.REQUEST)
        );
        RestClient.get(`${API_BASE_URL}/subscription/getmysubscription`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_MY_SUBSCRIPTION.SUCCESS,
                        resp.data.value
                    ),
                );
                if (resp.data.value !== null) {
                    // const endTime = resp.data.value !== null && resp.data.value.subscription_End ? moment(resp.data.value.subscription_End).isAfter(new Date()) : false
                    let lapsMethod = resp.data.value && resp.data.value.lapsedMethod && resp.data.value.lapsedMethod === true ? true : false
                    localStorage.setItem('subscription', lapsMethod ? 400 : 200);
                } else if (resp.data.value === null) {
                    localStorage.setItem('subscription', 400);
                }

            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_MY_SUBSCRIPTION.FAILURE)
                );
                localStorage.setItem('subscription', 400);

            }

        }).catch((err) => {
            console.warn(JSON.stringify(err))
            dispatch(
                actionCreator(mainActionTypes.GET_MY_SUBSCRIPTION.FAILURE)
            );
        });

    };
};

//HAS_PAYMENT_METHOD
export const hasPaymentMethodAction = (history, type) => {
    return (dispatch) => {
        dispatch(
            actionCreator(mainActionTypes.HAS_PAYMENT_METHOD.REQUEST)
        );
        RestClient.get(`${API_BASE_URL}/method/haspaymentmethod`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.HAS_PAYMENT_METHOD.SUCCESS,
                        resp.data.value
                    ),
                );
                // localStorage.setItem('subscription', resp.status);
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {

                localStorage.setItem('subscription', resp.status);
                dispatch(
                    actionCreator(mainActionTypes.HAS_PAYMENT_METHOD.FAILURE)
                ); dispatch(getSubscriptionPlanAction(history, type));

            }

        }).catch((err) => {
            console.warn(JSON.stringify(err))
            dispatch(
                actionCreator(mainActionTypes.HAS_PAYMENT_METHOD.FAILURE)
            );
        });

    };
};

export const addUserPaymentAction = (requestObj, history, type, alert) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.ADD_USER_PAYMENT_METHOD.REQUEST));
        RestClient.post(`${API_BASE_URL}/method/adduserpaymentmethod`, JSON.stringify(requestObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.ADD_USER_PAYMENT_METHOD.SUCCESS,
                        resp.data
                    ),
                );
                dispatch(getSubscriptionPlanAction(history, type));
                // history.push('/explore-warehouses')
            } else {
                dispatch(
                    actionCreator(mainActionTypes.ADD_USER_PAYMENT_METHOD.FAILURE)
                );
                const { errors } = resp.data;
                
//
                if (errors && errors.CardNumber) {
                    alert.show(
                        { title: 'Error', discription: errors.CardNumber[0] },
                        { type: 'error' })
                } else if (errors && errors.CardOwnerLastName) {
                    alert.show(
                        { title: 'Error', discription: errors.CardOwnerLastName[0] },
                        { type: 'error' })
                } else if (errors && errors.CardOwnerFirstName) {
                    alert.show(
                        { title: 'Error', discription: errors.CardOwnerFirstName[0] },
                        { type: 'error' })
                } else if (resp.data && resp.data.value) {
                    alert.show(
                        { title: 'Error', discription: resp.data.value },
                        { type: 'error' })
                }

            }

        }).catch((err) => {
            console.warn(err)
            dispatch(
                actionCreator(mainActionTypes.ADD_USER_PAYMENT_METHOD.FAILURE)
            );
            alert.show(
                { title: 'Error', discription: JSON.stringify(err.message) },
                { type: 'error' })
        });

    };
};
// Action for getting subscriptions plan
export const getSubscriptionPlanAction = (history, type) => {
    return (dispatch) => {

        RestClient.get(`${API_BASE_URL}/subscription/getsubscription`).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.GET_SUBSCRIPTION_LIST.SUCCESS,
                        resp.data.value
                    ),
                );
                const data = {
                    "PriceId": resp.data.value.subscriptions[0].id
                }
                dispatch(subscribedPlanAction(data, history, type));
            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.GET_SUBSCRIPTION_LIST.FAILURE)
                );
            }

        }).catch((err) => {
            console.warn(JSON.stringify(err))
            dispatch(
                actionCreator(mainActionTypes.GET_SUBSCRIPTION_LIST.FAILURE)
            );
        });

    };
};

export const subscribedPlanAction = (requestObj, history, type) => {
    return (dispatch) => {
        dispatch(actionCreator(mainActionTypes.SUBSCRIBED_PLAN.REQUEST));
        RestClient.post(`${API_BASE_URL}/subscription/subscribeplan`, JSON.stringify(requestObj)).then((resp) => {
            if (resp.status === 200) {
                dispatch(
                    actionCreator(
                        mainActionTypes.SUBSCRIBED_PLAN.SUCCESS,
                        resp.data
                    ),
                );
                localStorage.removeItem('lockPage')
                localStorage.setItem('subscription', 200);
                if (type.toLowerCase() === 'supplier'.toLowerCase()) {
                    history.push('/');
                } else if (type.toLowerCase() === 'Shipper'.toLowerCase()) {
                    history.push('/');
                } else {
                    history.push('/login');
                }

            } else if (resp.status === 401) {
                logoutAction(history);
            } else {
                dispatch(
                    actionCreator(mainActionTypes.SUBSCRIBED_PLAN.FAILURE)
                );
            }

        }).catch((err) => {
            dispatch(
                actionCreator(mainActionTypes.SUBSCRIBED_PLAN.FAILURE)
            );
        });

    };
};
