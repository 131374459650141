import {Popover, Transition} from '@headlessui/react';
import {Fragment, useEffect, useState} from 'react';
import {getConnection} from '../Dashboard/signalR';
import {getAccessTokenFromLocalStorage} from '../../../utils';
import {getNotificationAPI, readMotificationAPI} from '../../../actions';

import Lottie from 'react-lottie-player';
import notifyBell from '../../../assets/anim/notification.json';
import {BellIcon} from '@heroicons/react/outline';

export default function NotificationList({children, setCountFunc}) {
	const [solutions, setSolutions] = useState([]);
	useEffect(() => {
		loadMessages();
	}, []);

	const loadMessages = () => {
		const token = getAccessTokenFromLocalStorage();
		if (token) {
			handleNotification(token);
			getNotificationAPI()
				.then((resp) => {
					console.log(resp, '>>>>>>>>>>>>>>>>>>');
					setSolutions(resp);
					setCountFunc(resp.length);
				})
				.catch((err) => {
					//
				});
		}
	};

	const handleNotification = (token) => {
		let connection = getConnection(token);
		//
		//
		connection.on('ProposalMessage', (data) => {
			//
			//
			if (data) {
				const obj = {
					description: data.message && data.message.message,
					href: data.message && data.message.url,
					name: 'Reports4',
					icon: IconThree,
				};
				//
				//
				const newArr = [...solutions, obj];
				//
				//
				setCountFunc(newArr.length);
				setSolutions([...solutions, obj]);
				// const jsonData = JSON.parse(data);
			}
		});
	};
	const readNotification = (id) => {
		//
		console.log(id);
		if (id) {
			readMotificationAPI([id])
				.then((response) => {
					console.log(response);
					loadMessages();
				})
				.catch((err) => {
					console.log(err, 'error in reading marked ');
				});
		}
	};
	console.log(solutions, 'solutions');
	return (
		<Popover className="relative">
			{({open}) => (
				<>
					{
						//
					}
					<Popover.Button
						className={`${
							open ? '' : 'text-opacity-90'
						} text-white group rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
					>
						{children}
					</Popover.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-5 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-md">
							<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
								<div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1 max-h-96 overflow-y-scroll">
									{solutions &&
										solutions.length > 0 &&
										solutions.map((item, i) => {
											return (
												<a
													key={i.toString()}
													onClick={() => readNotification(item.id)}
													// target={'_'}
													className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
												>
													<div className="flex justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
														<BellIcon
															className="h-6 w-6 text-gray-900"
															aria-hidden="true"
														/>
													</div>
													<div className="ml-4 text-left">
														<p className="text-sm font-medium text-gray-900">
															{/* {'item.name'} */}
														</p>
														<p className="text-sm text-gray-500">
															{item.message || ''}
														</p>
													</div>
												</a>
											);
										})}
									{solutions && solutions.length > 0 ? null : (
										<div className="flex flex-col items-center mx-auto">
											<Lottie
												loop={false}
												play={true}
												animationData={notifyBell}
												// segments={segmentsEnabled && segments}
												style={{
													width: 150,
													height: 150,
													alignSelf: 'center',
												}}
											/>
											<span className="text-gray-400 text-sm">
												No updates yet!
											</span>
										</div>
									)}
								</div>
								<div className="flex flex-row bg-gray-50">
									<a
										href="##"
										className="flex flex-row justify-between w-full px-4 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
									>
										{/* <span className="text-sm font-medium text-gray-900">
                      Notification
                    </span> */}
										<span className="text-sm text-gray-500">
											Manage Notifications
										</span>
									</a>
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
}

function IconOne() {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="8" fill="#FFEDD5" />
			<path
				d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
				stroke="#FB923C"
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
				stroke="#FDBA74"
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
				stroke="#FDBA74"
				strokeWidth="2"
			/>
		</svg>
	);
}

function IconTwo() {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="8" fill="#FFEDD5" />
			<path
				d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
				stroke="#FB923C"
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
				stroke="#FDBA74"
				strokeWidth="2"
			/>
		</svg>
	);
}

function IconThree() {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="8" fill="#FFEDD5" />
			<rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
			<rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
			<rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
			<rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
			<rect x="29" y="16" width="2" height="20" fill="#FB923C" />
			<rect x="33" y="12" width="2" height="24" fill="#FB923C" />
		</svg>
	);
}
