import {useMemo, useState, Fragment} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {ArrowSmRightIcon} from '@heroicons/react/outline';
import {emailRegex} from '../../../../utils';
import {shipperRegisteration, supplierRegisteration} from '../../../../actions';
import {useDispatch, useSelector} from 'react-redux';
import SpinnerLottie from '../../CommonModules/spinnerLottie';
import {Transition} from '@headlessui/react';

export default function Register({validateRegister, type, alert}) {
	const dispatch = useDispatch();
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [phone, setPhone] = useState('');
	const [fphone, setFphone] = useState('');
	const [stepFirstFrom, setStepFirstFrom] = useState(false);
	const [countryCode, setCountryCode] = useState('');
	const {signupData, loading} = useSelector((state) => state.user);

	const validateForm = (e) => {
		e.preventDefault();
		setStepFirstFrom(true);
		if (type === 'shipper') {
			if (
				fname &&
				lname &&
				email &&
				emailRegex.test(email) &&
				password &&
				password.length >= 6 &&
				/^(?=.*[A-Z])/.test(password) &&
				phone
			) {
				const data = {
					firstName: fname,
					lastName: lname,
					email: email,
					Password: password,
					CountryCode: countryCode ? countryCode : null,
					PhoneNumber: phone,
				};
				dispatch(shipperRegisteration(data, alert));
			}
		} else {
			if (
				fname &&
				email &&
				emailRegex.test(email) &&
				password &&
				password.length >= 6 &&
				/^(?=.*[A-Z])/.test(password) &&
				phone
			) {
				const data = {
					ContactPerson: fname,
					email: email,
					Password: password,
					CountryCode: countryCode ? countryCode : null,
					PhoneNumber: phone,
				};
				dispatch(supplierRegisteration(data, alert));
			}
		}
	};
	const onChangePhone = (value, country, event, formattedValue) => {
		if (country) {
			setPhone(value.substring(country.dialCode.length, value.length));
			setFphone(formattedValue);
			setCountryCode('+' + country.dialCode);
		}
	};

	useMemo(() => {
		if (!loading && signupData) {
			setStepFirstFrom(false);
			validateRegister(email);
		}
	}, [email, loading, signupData, validateRegister]);
	return (
		<Transition
		appear
		as={Fragment}
		show={true}
		enter="transition ease-out duration-100"
		enterFrom="transform opacity-0 scale-95"
		enterTo="transform opacity-100 scale-100"
		leave="transition ease-in duration-75"
		leaveFrom="transform opacity-100 scale-100"
		leaveTo="transform opacity-0 scale-95"
	  >
		<div className="relative flex-1 md:px-5 md:py-10">
			<div className="flex-1 bg-gray-900 bg-opacity-10 w-full h-full rounded-md px-8 overflow-y-auto">
				<div className="pt-8">
					<div>
						<h3 className="text-lg leading-6 font-medium text-gray-200">
							Account Information
						</h3>
						<p className="mt-1 text-sm text-gray-400">
							Please enter the required fields to continue.
						</p>
					</div>
					<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
						<div className={`sm:col-span-${type === 'shipper' ? '3' : '4'}`}>
							<label
								htmlFor="first-name"
								className="block text-sm font-medium text-gray-200"
							>
								{type === 'shipper' ? 'First name' : 'Contact Person'}
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="first-name"
									id="first-name"
									autoComplete="given-name"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={fname}
									placeholder={
										type === 'shipper'
											? 'Please enter your first name'
											: 'Full name'
									}
									onChange={(e) => setFname(e.target.value)}
								/>
								{stepFirstFrom && !fname && (
									<div className="block text-sm font-medium text-red-500">
										{type === 'shipper'
											? 'First name is required'
											: 'Contact name is required'}
									</div>
								)}
							</div>
						</div>
						{type === 'shipper' && (
							<div className="sm:col-span-3">
								<label
									htmlFor="last-name"
									className="block text-sm font-medium text-gray-200"
								>
									Last name
								</label>
								<div className="mt-1">
									<input
										type="text"
										name="last-name"
										id="last-name"
										autoComplete="family-name"
										className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={lname}
										placeholder={'Please enter your last name'}
										onChange={(e) => setLname(e.target.value)}
									/>
									{stepFirstFrom && !lname && (
										<div className="block text-sm font-medium text-red-500">
											Last name is required
										</div>
									)}
								</div>
							</div>
						)}
						<div className="sm:col-span-4">
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-200"
							>
								Email address
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{stepFirstFrom && !email && (
									<div className="block text-sm font-medium text-red-500">
										Email is required
									</div>
								)}
								{stepFirstFrom && email && !emailRegex.test(email) && (
									<div className="block text-sm font-medium text-red-500">
										Email is invalid
									</div>
								)}
							</div>
						</div>
						<div className="sm:col-span-3">
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-200"
							>
								New Password
							</label>
							<div className="mt-1">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
								/>
								{stepFirstFrom && !password && (
									<div className="block text-sm font-medium text-red-500">
										Password is required
									</div>
								)}
								{stepFirstFrom &&
									password &&
									password.length < 5 &&
									!/^(?=.*[A-Z])/.test(password) && (
										<div className="block text-sm font-medium text-red-500">
											Password should be minimum 6 characters
										</div>
									)}

								{stepFirstFrom &&
									password &&
									password.length > 5 &&
									!/^(?=.*[A-Z])/.test(password) && (
										<div className="block text-sm font-medium text-red-500">
											Password should be one capital and one special
										</div>
									)}
							</div>
						</div>
						<div className="sm:col-span-3">
							<label
								htmlFor="phone"
								className="block text-sm font-medium text-gray-200"
							>
								Phone
							</label>
							<div className="mt-1">
								<PhoneInput
									inputClass="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									countryCodeEditable={false}
									country={'us'}
									enableSearch={true}
									placeholder="Phone Number"
									value={fphone}
									onChange={(value, country, e, formattedValue) =>
										onChangePhone(value, country, e, formattedValue)
									}
									inputStyle={{width: '100%'}}
								/>
								{stepFirstFrom && !phone && (
									<div className="block text-sm font-medium text-red-500">
										Phone number is required
									</div>
								)}
							</div>
						</div>

						<div className="sm:col-span-6">
							<div className="grid grid-cols-4">
								<button
									type="button"
									onClick={validateForm}
									className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
								>
									{loading ? (
										<SpinnerLottie isOpen={loading} />
									) : (
										<>
											Continue
											<ArrowSmRightIcon
												className="h-6 w-6 mr-1"
												aria-hidden="true"
											/>
										</>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</Transition>
	);
}
