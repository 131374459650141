import {
    createRequestActionTypes
} from '../utils/reduxMethods';


export const mainActionTypes = {
    SIGNUP: createRequestActionTypes('SIGNUP'),
    VERIFY: createRequestActionTypes('VERIFY'),
    DROPDOWN_GETALL: createRequestActionTypes('DROPDOWN_GETALL'),
    RESEND_CODE: createRequestActionTypes('RESEND_CODE'),
    LOGIN: createRequestActionTypes('LOGIN'),
    LOGOUT: createRequestActionTypes('LOGOUT'),
    EXPLORE_SHIPPERS: createRequestActionTypes('EXPLORE_SHIPPERS'),
    GET_SOW_ID: createRequestActionTypes('GET_SOW_ID'),
    GET_CONTRACTS: createRequestActionTypes('GET_CONTRACTS'),
    GET_PROPOSALS: createRequestActionTypes('GET_PROPOSALS'),
    GET_INCOMING_PROPOSALS: createRequestActionTypes('GET_INCOMING_PROPOSALS'),
    GET_WAREHOUSE_BY_USER: createRequestActionTypes('GET_WAREHOUSE_BY_USER'),
    GET_ALL_SOWS: createRequestActionTypes('GET_ALL_SOWS'),
    GET_MY_SUBSCRIPTION: createRequestActionTypes('GET_MY_SUBSCRIPTION'),
    HAS_PAYMENT_METHOD: createRequestActionTypes('HAS_PAYMENT_METHOD'),
    SCOPE_CREATED: createRequestActionTypes('SCOPE_CREATED'),
    ADD_USER_PAYMENT_METHOD: createRequestActionTypes('ADD_USER_PAYMENT_METHOD'),
    SUBSCRIBED_PLAN: createRequestActionTypes('SUBSCRIBED_PLAN'),
    GET_SUBSCRIPTION_LIST: createRequestActionTypes('GET_SUBSCRIPTION_LIST'),
    FILTER_BY_SCOPE: createRequestActionTypes('FILTER_BY_SCOPE'),
    FILTER_BY_TYPE: createRequestActionTypes('FILTER_BY_TYPE'),
};