import { combineReducers } from 'redux'
import user from './user'
import wareHouseReducer from './wareHouseReducer';
import shipperReducer from './shipperReducer';
import paymentReducer from './paymentReducer';


const appReducer = combineReducers({
    /* your app’s top-level reducers */
    user: user,
    warehouseData: wareHouseReducer,
    shipperData: shipperReducer,
    paymentData: paymentReducer
})
const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'LOGOUT_SUCCESS') {
        state = undefined;
    }

    return appReducer(state, action);
};
export default rootReducer;
