import {Fragment, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import HeaderBox from './components/header';
import {
	getAllSowsShipper,
	getMySubscriptionAction,
	hasPaymentMethodAction,
} from '../../../actions';
import ShipperOnBoarding from './components/shipperOnBoarding';
import Warehouse from '../myWareHouse/components/wareHouse';
import Loader from '../CommonModules/loader';
import WarehouseToggle from './components/warehouseToggle';
import NotFound from '../CommonModules/notFound';

import {startDateConstant} from '../../../constants';
import {filterWarehouseByType, pairwarehouseList} from '../../../actions';

export default function ShipperDashboard() {
	const [current, setCurrent] = useState(true);
	const [preLoader, setPreLoader] = useState(true);
	const [manageScope, setManageScope] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const {allSows, warehouses, loading} = useSelector(
		(state) => state.shipperData
	);
	// const {subscription} = useSelector((state) => state.paymentData);
	const subscription = localStorage.getItem('subscription');
	useMemo(() => {
		// dispatch(getMySubscriptionAction(history));
		dispatch(hasPaymentMethodAction(history, 'Shipper'));
		dispatch(getAllSowsShipper(history));
	}, [dispatch, history]);

	const [premiumBG, setPremiumBG] = useState(subscription === '400' ? 1 : 0);
	const currentRef = (index) => {
		setPremiumBG(index);
	};
	const handleSearchFilter = () => {
		const obj = {
			FacilityCertifications: null,
			Latitude: 0,
			Location: null,
			Longitude: 0,
			RequiredSqaureFootage: 0,
			StartDate: startDateConstant,
		};
		dispatch(filterWarehouseByType(obj, history));
	};
	useMemo(() => {
		setPremiumBG(subscription === '400' ? 1 : 0);
	}, [subscription]);
	useMemo(() => {
		setTimeout(() => {
			setPreLoader(false);
		}, 1000);
	}, []);
	const closeScope = () => {
		setManageScope(false);
	};

	const warehouseType = (e, name) => {
		e.preventDefault();
		if (name === 'Marketplace') {
			setCurrent(true);
			handleSearchFilter();
		} else {
			setCurrent(false);
			dispatch(pairwarehouseList(history));
		}
	};
	return (
		<Fragment>
			<header className="shadow-sm bg-gray-200">
				<HeaderBox
					selectedIndex={premiumBG}
					subscribedUser={subscription}
					setCurrent={setCurrent}
					manageScope={manageScope}
					closeScope={closeScope}
					setManageScope={setManageScope}
				/>
			</header>
			<TableWrapperHoc style={{background: premiumBG ? '#fff0db' : 'white'}}>
				{subscription === '400' ? (
					!preLoader && (
						<ShipperOnBoarding
							currentIndex={currentRef}
							history={history}
							sowList={allSows}
							subscribedUser={subscription}
							setPremiumBG={setPremiumBG}
						/>
					)
				) : (
					<Fragment>
						{subscription === '200' && (
							<>
								<WarehouseToggle
									current={current}
									warehouseType={warehouseType}
								/>
								<Warehouse list={warehouses} loader={loading} />
							</>
						)}
					</Fragment>
				)}
			</TableWrapperHoc>
		</Fragment>
	);
}
