import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
	loginAction,
	getMySubscriptionAction,
	saveOneSignalToken,
} from '../../../actions';
import {
	emailRegex,
	setCookie,
	eraseCookie,
	isUserAuthenticated,
	getAccessTokenFromLocalStorage,
	GetTokenDetails,
	findUserRoleRoute,
} from '../../../utils';
import popLogo from '../../../assets/brand/poplogo.svg';
import Lottie from 'react-lottie';
import loaderAnim from '../../../assets/anim/loader_white.json';
import {useAlert} from 'react-alert';
import {InformationCircleIcon} from '@heroicons/react/outline';

function AuthPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [checked, setChecked] = useState(false);
	const [objSignal, setObjSignal] = useState({});

	const {loading} = useSelector((state) => state.user);
	const alert = useAlert();
	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (email && emailRegex.test(email) && pass && pass.trim() !== '') {
			const obj = {
				username: email,
				Password: pass,
			};
			if (process.env.NODE_ENV === 'development') {
				dispatch(loginAction(obj, history, alert, {}));
			} else {
				// window.OneSignal.getUserId()
				// 	.then((resp) => {
				// 		const objSignalToken = {
				// 			token: resp,
				// 		};
				// 		if (objSignalToken) {
				// 			dispatch(loginAction(obj, history, alert, objSignalToken));
				// 		} else {
				// 		}
				// 	})
				// 	.catch((err) => {
				// 		dispatch(loginAction(obj, history, alert, {}));
				// 	});
			}
		}
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loaderAnim,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	useEffect(() => {
		// window.OneSignal.getUserId().then((resp) => {
		// 	const objSignal = {
		// 		token: resp,
		// 	};
		// 	setObjSignal(objSignal);
		// });
	}, []);

	const handleRemember = (e) => {
		const val = e.target.checked;
		if (val) {
			setChecked(val);
			setCookie('pop_user', email, 60);
		} else {
			setChecked(false);
			eraseCookie('pop_user');
		}
	};

	useEffect(() => {
		const token = getAccessTokenFromLocalStorage();
		if (token) {
			const isValidUser = isUserAuthenticated(token);
			if (isValidUser) {
				const type = GetTokenDetails(token);
				dispatch(getMySubscriptionAction(history));
				findUserRoleRoute(type.actort, history);
			}
		}
	}, [history]);
	const createAccount = () => {
		history.push('/signup');
	};
	return (
		<div className="min-h-screen bg-indigo-900 flex">
			<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div className="flex items-center flex-col mb-16">
						<img className="h-8 w-auto" src={popLogo} alt="Workflow" />
					</div>

					<div className="mt-8">
						<div className="mt-6">
							<div className="justify-between space-y-6 flex flex-col">
								<div>
									<label
										htmlFor="email"
										className="block text-sm font-medium text-gray-200"
									>
										Email address
									</label>
									<div className="mt-1 relative">
										<input
											id="email"
											name="email"
											type="email"
											autoComplete="email"
											value={email}
											onChange={(e) => {
												e.preventDefault();
												setEmail(e.target.value);
											}}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>

										{submitted && !email && (
											<span className="flex flex-row text-sm font-medium text-white bg-red-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
												<InformationCircleIcon className="push w-4 inline-block mr-1" />
												<span className="h-5">Required</span>
											</span>
										)}
										{submitted && email && !emailRegex.test(email) && (
											<span className="flex flex-row text-sm font-medium text-white bg-yellow-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
												<InformationCircleIcon className="push w-4 inline-block mr-1" />
												<span className="h-5">Invalid Email</span>
											</span>
										)}
									</div>

									<div className="space-y-1 mt-6">
										<label
											htmlFor="password"
											className="block text-sm font-medium text-gray-200"
										>
											Password
										</label>

										<div className="mt-1 relative">
											<input
												id="password"
												name="password"
												type="password"
												autoComplete="current-password"
												value={pass}
												onChange={(e) => {
													e.preventDefault();
													setPass(e.target.value);
												}}
												onKeyDown={(e) =>
													e.key === 'Enter' ? handleSubmit(e) : ''
												}
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											/>
											{submitted && !pass && (
												<span className="flex flex-row text-sm font-medium text-white bg-red-500 rounded-full px-2 absolute right-0 top-0 bottom-0 h-6 my-auto mr-2 items-center">
													<InformationCircleIcon className="push w-4 inline-block mr-1" />
													<span className="h-5">Required</span>
												</span>
											)}
										</div>
									</div>

									<div className="mt-3 flex flex-col md:flex-row items-center justify-between">
										<div className="flex items-center">
											<input
												id="remember_me"
												name="remember_me"
												type="checkbox"
												className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
												value={checked}
												onChange={handleRemember}
											/>
											<label
												htmlFor="remember_me"
												className="ml-2 block text-sm text-gray-400"
											>
												Remember me
											</label>
										</div>

										<div className="text-sm">
											<Link
												to={'/forgot-password'}
												className="font-medium text-gray-400 hover:text-gray-300"
											>
												Forgot your password?
											</Link>
										</div>
									</div>
								</div>

								<div className="">
									<div className="text-xs mb-6 mt-10 text-center">
										<span className="font-medium text-gray-400">
											By signing in, you agree to our{' '}
										</span>
										<Link
											to={'/signup'}
											className="font-medium text-gray-100 hover:text-gray-300"
										>
											Terms and Conditions,{' '}
										</Link>
										<Link className="font-medium text-gray-100 hover:text-gray-300">
											Cookies
										</Link>
										<span className="font-medium text-gray-400"> and </span>
										<Link className="font-medium text-gray-100 hover:text-gray-300">
											Privacy Policy
										</Link>
									</div>
									<button
										type="submit"
										name="create"
										onClick={createAccount}
										className="w-full flex justify-center py-2 px-4 border-white border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-white hover:bg-opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-6 border-2 mb-4"
									>
										Create an account
									</button>
									<button
										type="submit"
										name="signin"
										onClick={handleSubmit}
										className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{loading ? (
											<Lottie
												isClickToPauseDisabled={true}
												height={26}
												width={140}
												options={defaultOptions}
											/>
										) : (
											'Sign in'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden lg:block relative w-0 flex-1">
				<img
					className="absolute inset-0 h-full w-full object-cover"
					src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
					alt=""
				/>
			</div>
		</div>
	);
}
export default React.memo(AuthPage);
