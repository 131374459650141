import { mainActionTypes } from '../actions/actionTypes';

const initialState = {
    loading: false,
    allSows: [],
    scopeCreated: false,
    warehouses: []
};

export default function shipperReducer(state = initialState, { type, payload }) {
    switch (type) {
        case mainActionTypes.GET_ALL_SOWS.REQUEST:
            return {
                ...state,
                loading: true,
                allSows: []
            };
        case mainActionTypes.GET_ALL_SOWS.SUCCESS:
            return {
                ...state,
                loading: false,
                allSows: payload,
            };
        case mainActionTypes.GET_ALL_SOWS.FAILURE:
            return {
                ...state,
                loading: false,
                scopeCreated: false
            };
        case mainActionTypes.SCOPE_CREATED.REQUEST:
            return {
                ...state,
                loading: true,
                scopeCreated: true
            };
        case mainActionTypes.SCOPE_CREATED.SUCCESS:
            return {
                ...state,
                loading: false,
                scopeCreated: false
            };
        case mainActionTypes.SCOPE_CREATED.FAILURE:
            return {
                ...state,
                loading: false,
                allSows: []
            };
        case mainActionTypes.FILTER_BY_SCOPE.REQUEST:
            return {
                ...state,
                loading: true,
                warehouses: []
            };
        case mainActionTypes.FILTER_BY_SCOPE.SUCCESS:
            return {
                ...state,
                loading: false,
                warehouses: payload
            };
        case mainActionTypes.FILTER_BY_SCOPE.FAILURE:
            return {
                ...state,
                loading: false,
                warehouses: []
            };
        case mainActionTypes.FILTER_BY_TYPE.REQUEST:
            return {
                ...state,
                loading: true,
                warehouses: []
            };
        case mainActionTypes.FILTER_BY_TYPE.SUCCESS:
            return {
                ...state,
                loading: false,
                warehouses: payload
            };
        case mainActionTypes.FILTER_BY_TYPE.FAILURE:
            return {
                ...state,
                loading: false,
                warehouses: []
            };
        default:
            return state;
    }
};
