import {Fragment, useState} from 'react';
import {Transition} from '@headlessui/react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {CheckIcon} from '@heroicons/react/solid';
import Geocode from 'react-geocode';
import {classNames} from '../../../../utils';
import OverView from '../components/overView';
import {
	GOOGLE_API_KEY,
	arrayGetValueFilters,
	getState,
	getCity,
	getCountry,
	getStreetAddress,
	getZipcode,
	useCasesConst,
	projectStartDateConst,
	projectTermTypeConst,
	projectTermDurationTypeConst,
	inboundModeConst,
	outboundModeConst,
	transportationAssistanceConst,
	uomConst,
	dimensionConst,
	weightConst,
	quantityConst,
	stackableConst,
	rackableConst,
	volumeConst,
} from '../../../../utils';
import Commodity from '../components/commodity';
import Storage from '../components/storage';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {scopeCreatedAction} from '../../../../actions';
Geocode.setApiKey(GOOGLE_API_KEY);

export default function AddScopeModule({internalPage = false}) {
	const disptach = useDispatch();
	const history = useHistory();
	const [tabIndex, setTabIndex] = useState(0);
	const [searchLocation, setSearchLocation] = useState('');
	const [location, setLocation] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const [city, setCity] = useState('');
	const [states, setStates] = useState('');
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');
	const [visibleLoc, setVisibleLoc] = useState('');
	const valueFromLocalStorage = localStorage.getItem('filters');
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters('', 'facilityCertifications')
	);
	const [productWeightFormatted, setProductWeightFormatted] = useState('');
	const [productWeight, setProductWeight] = useState('');

	const [averageOutboundVolumeFormatted, setAverageOutboundVolumeFormatted] =
		useState('');
	const [averageOutboundVolume, setAverageOutboundVolume] = useState('');

	const [averageInboundVolumeFormatted, setAverageInboundVolumeFormatted] =
		useState('');
	const [averageInboundVolume, setAverageInboundVolume] = useState('');

	const [quantityFormatted, setQuantityFormatted] = useState('');
	const [quantity, setQuantity] = useState('');
	const [
		approxRequiredSqaureFootageFormatted,
		setApproxRequiredSqaureFootageFormatted,
	] = useState('');
	const [approxRequiredSqaureFootage, setApproxRequiredSqaureFootage] =
		useState('');

	const [averageInboundQtyFormatted, setAverageInboundQtyFormatted] =
		useState('');
	const [averageInboundQty, setAverageInboundQty] = useState('');

	const [averageOutboundQtyFormatted, setAverageOutboundQtyFormatted] =
		useState('');
	const [averageOutboundQty, setAverageOutboundQty] = useState('');
	const [stepFirstFrom, setStepFirstFrom] = useState(false);
	const [stepThirdFrom, setStepThirdFrom] = useState(false);
	const [final, setFinal] = useState(false);
	const [state, setState] = useState({
		scopeName: '',
		projectedStartDate: '',
		projectTerm: '',
		projectTermDurationType: '',
		projectTermType: '',
		useCase: '',
		commodity: '',
		productUoM: '',
		totalSKU: 0,
		productDimensions: '',
		productDimensions2: '',
		productDimensionsType: '',
		productWeightType: '',
		transportationAssistance: '',
		outboundMode: '',
		inboundMode: '',
		averageOutboundVolumeType: '',
		averageOutboundQtyType: '',
		quantityType: '',
		rackable: '',
		stackableType: '',
		stackable: '',
		rackableType: '',
		averageInboundQtyType: '',
		averageInboundVolumeType: '',
	});

	// eslint-disable-next-line no-unused-vars
	const jsonList = JSON.parse(valueFromLocalStorage);

	const steps = [
		{
			name: 'Overview',
			description: 'Enter initial details for your scope.',
			href: '#',
			status:
				tabIndex === 0 ? 'current' : tabIndex > 0 ? 'complete' : 'upcoming',
		},
		{
			name: 'Commodity Details',
			description: 'Enter your Commodity Details.',
			href: '#',
			status:
				tabIndex === 1 ? 'current' : tabIndex > 1 ? 'complete' : 'upcoming',
		},
		{
			name: 'Storage & Handling Requirements',
			description: 'Enter your Storage & Handling Requirements.',
			href: '#',
			status:
				tabIndex === 2 ? 'current' : tabIndex > 2 ? 'complete' : 'upcoming',
		},
	];

	const handleAddressSelect = (place) => {
		if (place && place.formatted_address) {
			const address = place.formatted_address,
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			Geocode.fromLatLng(latValue, lngValue).then(
				(response) => {
					const addressArray = response.results[0].address_components;
					setLatitude(latValue);
					setLongitude(lngValue);
					setCity(getCity(addressArray));
					setStates(getState(addressArray));
					setCountry(getCountry(addressArray));
					const streetAddress = getStreetAddress(addressArray);
					setZip(getZipcode(addressArray));
					setVisibleLoc(streetAddress ? streetAddress : address);
					setLocation(address);
					setSearchLocation('');
				},
				(error) => {
					console.error(error);
				}
			);
		} else {
			setCity('');
			setStates('');
			setCountry('');
			setZip('');
			setLocation(null);
			setSearchLocation('');
			setVisibleLoc('');
			setLatitude(null);
			setLongitude(null);
		}
	};
	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};
	const handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		if (name === 'totalSKU') {
			if (value === '') {
				setState({
					...state,
					[e.target.name]: value,
				});
			} else {
				if (!Number(value)) {
					return;
				} else {
					setState({
						...state,
						[e.target.name]: value,
					});
				}
			}
		} else {
			setState({
				...state,
				[e.target.name]: value,
			});
		}
	};
	const validateOverView = (e) => {
		e.preventDefault();
		if (state.scopeName && location && facilityList.length > 0) {
			setStepFirstFrom(false);
			setTabIndex(1);
		} else {
			setStepFirstFrom(true);
		}
	};
	const validateBack = (e, indexType) => {
		e.preventDefault();
		setTabIndex(indexType);
	};
	const validateCommodity = (e) => {
		e.preventDefault();
		setTabIndex(2);
	};
	const validateStorage = (e) => {
		e.preventDefault();
		const {
			scopeName,
			useCase,
			productDimensions,
			productDimensionsType,
			productUoM,
			projectTerm,
			productWeightType,
			projectTermDurationType,
			projectTermType,
			projectedStartDate,
			averageInboundQtyType,
			averageInboundVolumeType,
			averageOutboundQtyType,
			averageOutboundVolumeType,
			rackable,
			rackableType,
			stackable,
			stackableType,
			totalSKU,
			transportationAssistance,
			inboundMode,
			outboundMode,
			commodity,
			quantityType,
			productDimensions2,
		} = state;
		if (
			approxRequiredSqaureFootage &&
			parseFloat(approxRequiredSqaureFootage) > 0
		) {
			setFinal(true);
			const requestData = {
				ScopeName: scopeName,
				Location: location,
				Latitude: latitude,
				Longitude: longitude,
				FacilityCertifications: facilityList
					.map((ele) => {
						return ele.value;
					})
					.join(','),
				City: city,
				State: states,
				Zipcode: zip,
				Country: country,
				UseCase: useCase ? parseInt(useCase) : null,
				ProjectedStartDate: projectedStartDate
					? parseInt(projectedStartDate)
					: null,
				ProjectTerm: projectTerm ? parseFloat(projectTerm) : null,
				ProjectTermType: projectTermType ? parseInt(projectTermType) : null,
				ProjectTermDurationType: projectTermDurationType
					? parseInt(projectTermDurationType)
					: null,
				Commodity: commodity,
				ProductUoM: productUoM ? parseInt(productUoM) : null,
				ProductUoMOther: null,
				ProductDimensions: `${productDimensions}X${productDimensions2}`,
				ProductDimensionsType: productDimensionsType
					? parseInt(productDimensionsType)
					: null,
				ProductWeight: productWeight ? parseFloat(productWeight) : null,
				ProductWeightType: productWeightType
					? parseInt(productWeightType)
					: null,
				Quantity: quantity ? parseFloat(quantity) : null,
				QuantityType: quantityType ? parseInt(quantityType) : null,
				QuantityTypeOther: null,
				Stackable: stackable ? (stackable === 'true' ? true : false) : null,
				StackableType: stackableType ? parseInt(stackableType) : null,
				Rackable: rackable ? (rackable === 'true' ? true : false) : null,
				RackableType: rackableType ? parseInt(rackableType) : null,
				ApproxRequiredSqaureFootage: parseFloat(approxRequiredSqaureFootage),
				AverageInboundQty: averageInboundQty
					? parseFloat(averageInboundQty)
					: null,
				AverageInboundQtyType: averageInboundQtyType
					? parseInt(averageInboundQtyType)
					: null,
				AverageInboundQtyTypeOther: null,
				AverageInboundVolume: averageInboundVolume
					? parseFloat(averageInboundVolume)
					: null,
				AverageInboundVolumeType: averageInboundVolumeType
					? parseInt(averageInboundVolumeType)
					: null,
				AverageOutboundQty: averageOutboundQty
					? parseFloat(averageOutboundQty)
					: null,
				AverageOutboundQtyType: averageOutboundQtyType
					? parseInt(averageOutboundQtyType)
					: null,
				AverageOutboundQtyTypeOther: null,
				AverageOutboundVolume: averageOutboundVolume
					? parseFloat(averageOutboundVolume)
					: null,
				AverageOutboundVolumeType: averageOutboundVolumeType
					? parseInt(averageOutboundVolumeType)
					: null,
				TotalSKU: totalSKU ? parseInt(totalSKU) : 0,
				InboundMode: inboundMode ? parseInt(inboundMode) : null,
				OutboundMode: outboundMode ? parseInt(outboundMode) : null,
				TransportationAssistance: transportationAssistance
					? parseInt(transportationAssistance)
					: null,
				AvailableSoonSqaureFootage: null,
			};
			disptach(scopeCreatedAction(requestData, history, internalPage));
			setTimeout(() => {
				setFinal(false);
			}, 3000);
		} else {
			setStepThirdFrom(true);
			setFinal(false);
		}
		// alert('api call ');
	};
	return (
		<Fragment>
			<Tabs
				selectedIndex={tabIndex}
				// onSelect={(index) => setTabIndex(index)}
				// className="h-full w-full"
			>
				<div className="flex flex-1 md:flex-row flex-col h-full w-full rounded-lg overflow-hidden">
					<div className="flex md:max-w-sm w-full h-20 md:h-full">
						<nav aria-label="Progress">
							<TabList className="overflow-hidden">
								{steps.map((step, stepIdx) => (
									<Tab
										key={step.name}
										className={classNames(
											stepIdx !== steps.length - 1 ? 'pb-10' : '',
											'relative'
										)}
									>
										{step.status === 'complete' ? (
											<>
												{stepIdx !== steps.length - 1 ? (
													<div
														className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
														aria-hidden="true"
													/>
												) : null}
												<a
													href={step.href}
													className="relative flex items-start group"
												>
													<span className="h-9 flex items-center">
														<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
															<CheckIcon
																className="w-5 h-5 text-white"
																aria-hidden="true"
															/>
														</span>
													</span>
													<span className="ml-4 min-w-0 flex flex-col">
														<span className="text-xs font-semibold tracking-wide uppercase">
															{step.name}
														</span>
														<span className="text-sm text-gray-500">
															{step.description}
														</span>
													</span>
												</a>
											</>
										) : step.status === 'current' ? (
											<>
												{stepIdx !== steps.length - 1 ? (
													<div
														className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
														aria-hidden="true"
													/>
												) : null}
												<a
													href={step.href}
													className="relative flex items-start group"
													aria-current="step"
												>
													<span
														className="h-9 flex items-center"
														aria-hidden="true"
													>
														<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
															<span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
														</span>
													</span>
													<span className="ml-4 min-w-0 flex flex-col">
														<span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
															{step.name}
														</span>
														<span className="text-sm text-gray-500">
															{step.description}
														</span>
													</span>
												</a>
											</>
										) : (
											<>
												{stepIdx !== steps.length - 1 ? (
													<div
														className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
														aria-hidden="true"
													/>
												) : null}
												<a
													href={step.href}
													className="relative flex items-start group"
												>
													<span
														className="h-9 flex items-center"
														aria-hidden="true"
													>
														<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
															<span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
														</span>
													</span>
													<span className="ml-4 min-w-0 flex flex-col">
														<span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
															{step.name}
														</span>
														<span className="text-sm text-gray-500">
															{step.description}
														</span>
													</span>
												</a>
											</>
										)}
									</Tab>
								))}
							</TabList>
						</nav>
					</div>
					{/* add padding to the right block here if needed */}
					<div className="w-full h-full">
						<TabPanel>
							<Transition
								appear
								show={true}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<OverView
									searchLocation={searchLocation}
									handleAddressSelect={handleAddressSelect}
									setLocation={setLocation}
									setSearchLocation={setSearchLocation}
									GOOGLE_API_KEY={GOOGLE_API_KEY}
									location={location}
									jsonList={jsonList}
									facilityList={facilityList}
									handleDropdownChangeFacility={handleDropdownChangeFacility}
									useCasesConst={useCasesConst}
									handleChange={handleChange}
									projectStartDateConst={projectStartDateConst}
									projectTermTypeConst={projectTermTypeConst}
									projectTermDurationTypeConst={projectTermDurationTypeConst}
									state={state}
									visibleLoc={visibleLoc}
									validateOverView={validateOverView}
									stepFirstFrom={stepFirstFrom}
								/>
							</Transition>
						</TabPanel>
						<TabPanel className="h-full w-full">
							<Transition
								appear
								show={true}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Commodity
									uomConst={uomConst}
									dimensionConst={dimensionConst}
									weightConst={weightConst}
									handleChange={handleChange}
									state={state}
									validateBack={validateBack}
									setProductWeightFormatted={setProductWeightFormatted}
									productWeightFormatted={productWeightFormatted}
									productWeight={productWeight}
									setProductWeight={setProductWeight}
									validateCommodity={validateCommodity}
								/>
							</Transition>
						</TabPanel>
						<TabPanel className="h-full w-full">
							<Transition
								appear
								show={true}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Storage
									handleChange={handleChange}
									validateBack={validateBack}
									validateStorage={validateStorage}
									weightConst={weightConst}
									stackableConst={stackableConst}
									rackableConst={rackableConst}
									quantityConst={quantityConst}
									volumeConst={volumeConst}
									inboundModeConst={inboundModeConst}
									outboundModeConst={outboundModeConst}
									transportationAssistanceConst={transportationAssistanceConst}
									state={state}
									setAverageInboundVolume={setAverageInboundVolume}
									setAverageInboundVolumeFormatted={
										setAverageInboundVolumeFormatted
									}
									averageInboundVolumeFormatted={averageInboundVolumeFormatted}
									averageInboundVolume={averageInboundVolume}
									setQuantityFormatted={setQuantityFormatted}
									quantityFormatted={quantityFormatted}
									setQuantity={setQuantity}
									quantity={quantity}
									setAverageOutboundVolumeFormatted={
										setAverageOutboundVolumeFormatted
									}
									averageOutboundVolumeFormatted={
										averageOutboundVolumeFormatted
									}
									setAverageOutboundVolume={setAverageOutboundVolume}
									averageOutboundVolume={averageOutboundVolume}
									setApproxRequiredSqaureFootageFormatted={
										setApproxRequiredSqaureFootageFormatted
									}
									setApproxRequiredSqaureFootage={
										setApproxRequiredSqaureFootage
									}
									approxRequiredSqaureFootageFormatted={
										approxRequiredSqaureFootageFormatted
									}
									approxRequiredSqaureFootage={approxRequiredSqaureFootage}
									setAverageInboundQtyFormatted={setAverageInboundQtyFormatted}
									averageInboundQtyFormatted={averageInboundQtyFormatted}
									setAverageInboundQty={setAverageInboundQty}
									averageInboundQty={averageInboundQty}
									setAverageOutboundQtyFormatted={
										setAverageOutboundQtyFormatted
									}
									averageOutboundQtyFormatted={averageOutboundQtyFormatted}
									setAverageOutboundQty={setAverageOutboundQty}
									averageOutboundQty={averageOutboundQty}
									stepThirdFrom={stepThirdFrom}
									final={final}
								/>
							</Transition>
						</TabPanel>
					</div>
				</div>
			</Tabs>
		</Fragment>
	);
}
